import { Button } from '@mui/material';
import './StandardPageWrapper.css';

function StandardPageWrapper({ header, btns, children }) {
    return (
        <div id='standardpageWrapper' >
            <section className="standardpageWrapper_header">
                <h1>{header}</h1>
                <div className="standardpageWrapper_headerBtnCont">
                    {btns}
                </div>
            </section>
            <section className='standardpageWrapper_dataCont' >
                {children}
            </section>
        </div>
    )
}

export default StandardPageWrapper