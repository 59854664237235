import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
// import MDBackdrop from "components/Custom/MDBackdrop";
import axios from "axios";
import * as XLSX from 'xlsx';

function POPUploadCSV(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [file, setFile] = useState([]);
    const [data, setData] = useState([]);
    const [fileName, setFileName] = useState("");
    const [isBackdrop, setIsBackdrop] = useState(false);
    const { onClose } = props;

    const uploadFile = async () => {
       if (!file) return enqueueSnackbar('School ID Not Found !!!', { variant: 'error' });

        // Create FormData
        const formData = new FormData();
        formData.append("file", file);
        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/importteacherwithclass`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(res => {
                if (res.data.success) {
                    // if (res.data.data?.length <= 0) return enqueueSnackbar('No Data Found', { variant: 'error' })
                    enqueueSnackbar("Excel Uploaded Successfully!!", { variant: 'success' })
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
            })
            .catch(err => {
                console.log(err);
                if(err?.response?.data?.success === false){
                    return enqueueSnackbar(err?.response?.data?.message, { variant: 'error' })
                }
                return enqueueSnackbar('Something Went Wrong While Uploading !!!', { variant: 'error' })
            })
    }

    const fetchData = async (file, fileName, data) => {
        console.log(fileName);
        console.log(file);
        console.log(data);
        var status1 = 1;
        for (let i = 1; i < data.length; i++) {
            // Create a JSON object for each row
            const rowData = { last_name: "" }; // Initialize with default value for last_name
            // Add each column's value based on header row
            data[0].forEach((header, index) => {
                rowData[header] = data[i][index];
            });
            // Check if all required fields have values
            const requiredFields = ["first_name", "phone", "standard", "schoolid"];
            const isComplete = requiredFields.every((field) => rowData[field]);

            if (!isComplete) {
                alert(`Error: Missing required fields at row ${i + 1}. Please fill all details.`);
                status1 = 0;
                break;
            }
            try {
                setIsBackdrop(true);
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/user/addclasswithclassteacher`,
                    // bodyFormData,
                    JSON.stringify(rowData),// Send rowData as JSON
                    {
                        headers: { "Content-Type": "application/json" }
                    }
                );
                // .then((response) => {
                //     console.log(response.data.message);
                if (response.data.status === false) {
                    status1 = 0;
                    alert("There is some problem while loading row '" + (i + 1) + "' with username '" + data[i][0] + "' before that all teacher with class are loaded and from this username rectify the problem and upload all below teachers with class again.");
                    window.location.reload();
                }
            }
            // })
            catch (error) {
                status1 = 0;
                // alert(error);
                console.log(error);
                alert("There is some problem while loading row '" + (i + 1) + "' with username '" + data[i][0] + "' before that all teacher with class are loaded and from this username rectify the problem and upload all below teachers with class again.");
                window.location.reload();
            };
            console.log(status1);
            if (status1 === 0) {
                break;
            }
        }
        if (status1 === 1) {
            alert('File is uploaded');
            window.location.reload();
        }
        setIsBackdrop(false);
    };

    useEffect(() => { }, []);

    const handleFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Converts a worksheet object to an array of JSON objects*/
            const parsedData = XLSX.utils.sheet_to_json(ws, {
                header: 1,
                blankrows: false
            });
            console.log(parsedData);
            setData(parsedData);
        }
        reader.readAsBinaryString(f);
    };

    const handleSubmit = () => {
        if (!file) {
            return
        }
        setIsBackdrop(true);
        // fetchData(file, fileName, data);
        uploadFile();
    };
    const handleClose = () => {
        onClose(false);
    };

    return (
        <>
            {/* <MDBackdrop isBackdrop={isBackdrop} /> */}
            <Dialog open={props.isDialog} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>Select CSV File</DialogTitle>
                <DialogContent>
                    <Box pt={2} pb={3} px={3}>
                        <Box pt={2}>
                            <FormControl sx={{ width: "100%" }}>
                                {/* <MDInput type="file" name="file" onChange={handleFile} fullWidth required /> */}
                                <TextField
                                    type="file"
                                    name="file"
                                    onChange={handleFile}
                                    fullWidth
                                    required
                                    InputLabelProps={{ shrink: true }}  // Ensures label position is consistent
                                />
                            </FormControl>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} autoFocus>
                        Upload File
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default POPUploadCSV;