import './SideMenu.css'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from '../../img/final_logo.png'
import CryptoJS from 'crypto-js';
function SideMenu() {
    const navigate = useNavigate();
    const { pathname, state } = useLocation()

    const handleLinkClick = (e, link) => {
        e.preventDefault();
        navigate(link);
    }

    const sideMenuTabsArr = [
        {
            heading: 'ID Cards',
            link: '/idcards',
            activeRoutes: ['/idcards', `/idcards/${state?.id}`, '/idcards/create', '/download-by-excel']
        },
        {
            heading: 'Download by Date',
            link: '/download-by-date',
            activeRoutes: ['/download-by-date']
        },
        {
            heading: 'Download by School',
            link: '/download-by-school',
            activeRoutes: ['/download-by-school']
        },
        {
            heading: 'Download by Class',
            link: '/download-by-class',
            activeRoutes: ['/download-by-class']
        },
        {
            heading: 'Users',
            link: '/users',
            activeRoutes: ['/users']
        },
        {
            heading: 'Schools',
            link: '/schools',
            activeRoutes: ['/schools', `/schools/${state?.schoolid}`, `/schools/class/${state?.classid}`]
        },
        {
            heading: 'Logout',
            link: '/',
            activeRoutes: ['/']
        },
    ]

    if (sessionStorage?.getItem('ID_CardAdmin_user_auth')) {
        const bytes = CryptoJS.AES.decrypt(sessionStorage?.getItem('ID_CardAdmin_user_auth'), process.env.REACT_APP_CRYPTO_JS_SECRET);
        const decrypt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (decrypt) return;
    }

    if (pathname == '/login') return;
    return (
        <div id='sidemenu'>
            <section className='sidemenu_cont'>
                {/* <h2>Logo</h2> */}
                <div className='logo_container'>
                <img className='sidemenu_logo' src={Logo}></img>

                </div>
                <div className="sidemenu_sec1">
                    {sideMenuTabsArr?.map((t, i) => (
                        <div onClick={e => handleLinkClick(e, t.link)} key={i} className={`sidemenu_btn ${t.activeRoutes.includes(pathname) ? 'sidemenu_btn_active' : ''}`} >
                            {t.heading}
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}

export default SideMenu
