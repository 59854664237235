import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import StandardButton from '../StandardButton/StandardButton'

function StandardMuiDialogWrapper({ dialogTitle, open, setOpen, onExited, onSaveBtnClick, saveBtnLabel, saveBtnLoading, children }) {
    return (
        <Dialog fullWidth maxWidth='sm' open={open} className='standardDialogWrapper' TransitionProps={{ onExited: onExited }} >
            <DialogTitle className='standardDialogWrapper_title'>{dialogTitle}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <StandardButton color='#d32f2f' onClick={() => setOpen(false)} label='Cancel' />

                <StandardButton onClick={onSaveBtnClick} label={saveBtnLabel} color='darkgreen' loading={saveBtnLoading}/>
            </DialogActions>
        </Dialog>
    )
}

export default StandardMuiDialogWrapper