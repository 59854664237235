import './ReactCustomTable.css';
import { Fragment } from 'react';
import { CircularProgress } from '@mui/material';
import { Table } from 'rsuite';
import "rsuite/dist/rsuite.min.css";

const { Column, HeaderCell, Cell } = Table;

function ReactCustomTable({
    data, tableColumns, loading, tableActions, actionsColumnWidth, onRowClick, circularColor,
    deleteID, deleteLoading, rowDataDeleteKey,  // Delete
    downloadId, downloadLoading//Loading
}) {
    return (
        <Table
            height={500}
            // fillHeight
            virtualized
            data={data}
            loading={loading}
            onRowClick={onRowClick ? rowData => onRowClick(rowData) : () => { }}
        >
            {tableColumns?.map((c, index) => (
                <Column width={c?.width} fixed={c?.fixed} flexGrow={c?.flexGrow} align={c?.align || 'left'} fullText={c?.fullText}
                    resizable={!c?.flexGrow} minWidth={c?.minWidth} key={index}
                >
                    <HeaderCell >{c?.label}</HeaderCell>
                    <Cell dataKey={c?.key} />
                </Column>
            ))}

            {tableActions && (
                <Column width={actionsColumnWidth} fixed="right" align='center' >
                    <HeaderCell>Actions</HeaderCell>

                    <Cell>
                        {rowData => (
                            <div className='rsuite_actions_btnCont' >
                                {tableActions?.map((t, i) => (
                                    <Fragment key={i}>
                                        {t?.btnType == 'download' ? (
                                            <>
                                                {rowData[rowDataDeleteKey] == downloadId && downloadLoading ? (
                                                    <CircularProgress size={18} style={{ color: 'darkgreen', marginRight: 10 }} />
                                                ) : (
                                                    <div onClick={(e) => t.onClick(e, rowData)} >
                                                        {t?.icon}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {rowData[rowDataDeleteKey] == deleteID && deleteLoading && t?.btnType == 'del' ? (
                                                    <CircularProgress size={18} style={{ color: circularColor, marginRight: 10 }} />
                                                ) : (
                                                    <div onClick={(e) => t.onClick(e, rowData)} >
                                                        {t?.icon}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Fragment>
                                ))}
                            </div>
                        )}
                    </Cell>
                </Column>
            )
            }
        </Table >
    )
}

export default ReactCustomTable;