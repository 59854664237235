import { useState, useEffect } from "react";
import { Button, MenuItem, TextField, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";

function AddUser() {
    const { enqueueSnackbar } = useSnackbar();
    const [addUserData, setAddUserData] = useState({
        first_name: "",
        phone_no: "",
        role: "",
        classid: "",
        schoolid: "",
    });
    const [allSchoolData, setAllSchoolData] = useState([]);
    const [allClassData, setAllClassData] = useState([]);
    const [allSchoolDataLoading, setAllSchoolDataLoading] = useState(true);

    useEffect(() => {
        fetchAllSchools();
    }, []);

    const fetchAllSchools = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}/user/getallschool`).then((res) => {
            if (res.data.success) {
                setAllSchoolData(res.data.data);
            } else {
                enqueueSnackbar("Error Fetching Schools, Try Again !!!", { variant: "error" });
            }
            setAllSchoolDataLoading(false);
        }).catch((err) => {
            console.log(err);
            setAllSchoolDataLoading(false);
            enqueueSnackbar("Something Went Wrong !!!", { variant: "error" });
        });
    };

    const fetchAllClass = async (e) => {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/getclassesbyschoolid`, {
            schoolid: e.target.value,
        }).then((res) => {
            if (res.data.success) {
                setAllClassData(res.data.data);
            } else {
                enqueueSnackbar("Error Fetching Class, Try Again !!!", { variant: "error" });
            }
        }).catch((err) => {
            console.log(err);
            enqueueSnackbar("Something Went Wrong !!!", { variant: "error" });
        });
    };

    const onAddUser = async () => {
        if (Object.values(addUserData)?.includes("")) {
            return enqueueSnackbar("Please Fill All The Details !!!", { variant: "error" });
        }

        const body = { ...addUserData, is_verified: 1 };
        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/signup`, body).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("User Added Successfully !!!", { variant: "success" });
            } else {
                enqueueSnackbar(res.data.message, { variant: "error" });
            }
        }).catch((err) => {
            console.log(err);
            enqueueSnackbar("Something Went Wrong !!!", { variant: "error" });
        });
    };

    return (
        <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={8} lg={6}>
                <Typography
                    variant="h3"
                    gutterBottom
                    align="center"
                    sx={{ marginBottom: 6, fontWeight: 'bold' }}
                >
                    Add User
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="First Name"
                            fullWidth
                            value={addUserData.first_name}
                            onChange={(e) => setAddUserData({ ...addUserData, first_name: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Phone Number"
                            type="number"
                            fullWidth
                            value={addUserData.phone_no}
                            onChange={(e) => setAddUserData({ ...addUserData, phone_no: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="School"
                            select
                            fullWidth
                            value={addUserData.schoolid}
                            onChange={(e) => { setAddUserData({ ...addUserData, schoolid: e.target.value }); fetchAllClass(e); }}
                        >
                            {allSchoolData.map((c, i) => (
                                <MenuItem value={c.schoolid} key={i}>
                                    {c.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Class"
                            select
                            fullWidth
                            value={addUserData.classid}
                            onChange={(e) => setAddUserData({ ...addUserData, classid: e.target.value })}
                        >
                            {allClassData.map((c, i) => (
                                <MenuItem value={c.classid} key={i}>
                                    {c.division}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Role"
                            select
                            fullWidth
                            value={addUserData.role}
                            onChange={(e) => setAddUserData({ ...addUserData, role: e.target.value })}
                        >
                            <MenuItem value="student">Student</MenuItem>
                            <MenuItem value="teacher">Teacher</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="success" onClick={onAddUser} fullWidth>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AddUser;
