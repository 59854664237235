import './App.css';
import { Routes, Route, Navigate, useLocation  } from "react-router-dom"
// Components
import SideMenu from './components/SideMenu/SideMenu';
// Pages
import Login from './pages/Login/Login';
import DownloadByExcel from './pages/DownloadByExcel/DownloadByExcel';
import DownloadByDate from './pages/DownloadByDate/DownloadByDate';
import Users from './pages/Users/Users';
import Schools from './pages/SchoolsPages/Schools/Schools';
import Classroom from './pages/SchoolsPages/Classroom/Classroom';
import User from './pages/SchoolsPages/User/User';
import DownloadBySchool from './pages/DownloadBySchool/DownloadBySchool';   
import DownloadByClass from './pages/DownloadByClass/DownloadByClass';
import AddUser from './pages/AddUser/AddUser';

// import { Rightcontent } from './components/Rightcontent.js';
import { CreateIdCard } from './pages/CreateIdCard/CreateIdCard';
import IdCards from './pages/CreateIdCard/IdCards';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
function App() {
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   if (!sessionStorage.getItem("ID_CardAdmin_user", "admin")) {
  //     navigate("/");
  //   }
  // }, []);
  useEffect(() => {
    if (!sessionStorage.getItem("ID_CardAdmin_user", "admin") && location.pathname !== '/add-user') {
      navigate("/login");
    }
  }, [location.pathname, navigate]);

  return (
    <div id="app">
      {/* <SideMenu /> */}
      {location.pathname !== '/add-user' && <SideMenu />}
      <div className='app_routesCont' >
        <Routes>
          {/* <Route path="/right" element={<CreateIdCard />} /> */}
          <Route exact path="/" element={<Navigate to="/login" />} replace />
          <Route path="/login" element={<Login />} />
          <Route path="/idcards" element={<IdCards />} />
          <Route path="/idcards/:id" element={<CreateIdCard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/download-by-date" element={<DownloadByDate />} />          
          <Route path="/download-by-school" element={<DownloadBySchool />} />
          <Route path="/download-by-class" element={<DownloadByClass />} />
          <Route path="/download-by-excel" element={<DownloadByExcel />} />
          {/* SchoolPages */}
          <Route path="/schools" element={<Schools />} />
          <Route path="/schools/:id" element={<Classroom />} />
          <Route path="/schools/class/:id" element={<User />} />
          <Route path="/add-user" element={<AddUser />} /> 
        </Routes>
      </div>
    </div>
  );
}

export default App;
