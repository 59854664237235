import './DownloadBySchool.css';
import { useState, useEffect } from 'react';
import { Autocomplete, TextField, Checkbox, CircularProgress, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image-chrome-fix-retina';
// Components
import StandardPageWrapper from '../../components/StandardPageWrapper/StandardPageWrapper';
import CustomMuiSelect from '../../components/CustomMuiSelect/CustomMuiSelect';
import StandardButton from '../../components/StandardButton/StandardButton';
import StandardPreviewCardDownload from '../../components/StandardPreviewCardDownload/StandardPreviewCardDownload';
// ICONs & SVGs
import SearchIcon from '@mui/icons-material/Search';

function DownloadBySchool() {
    const { enqueueSnackbar } = useSnackbar();

    const [allIDCards, setAllIDCards] = useState([]);
    const [allIDCardsStorage, setAllIDCardsStorage] = useState([]); // Data in this card wont change
    const [allIDCardsCheckBox, setAllIDCardsCheckBox] = useState({});

    const [allBtnData, setAllBtnData] = useState({ school: '', is_verified: '' });
    console.log(allBtnData)
    const [allSchoolData, setAllSchoolData] = useState([]);

    const [allSchoolDataLoading, setAllSchoolDataLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);

    const [cardType, setCardType] = useState('all');

    // ----------------------
    const [downloadAllLoading, setDownloadAllLoading] = useState(false);
    const [downloadSelectedLoading, setDownloadSelectedLoading] = useState(false);

    useEffect(() => {
        fetchAllSchools();
    }, [])

    const fetchAsIsVerified = async (e) => {
        setAllBtnData({ ...allBtnData, is_verified: e.target.value })
      }
    const fetchAllSchools = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}/user/getallschool`)
            .then(res => {
                if (res.data.success) {
                    setAllSchoolData(res.data.data)
                } else {
                    enqueueSnackbar('Error Fetching Schools, Try Again !!!', { variant: 'error' })
                }
                setAllSchoolDataLoading(false);
            })
            .catch(err => {
                console.log(err);
                setAllSchoolDataLoading(false);
                return enqueueSnackbar('Something Went Wrong !!!', { variant: 'error' })
            })
    }

    const updateIsDownloaded = async () => {
        const payload = {
            "school_id": [allBtnData.school]
        }
        await axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/user/update_is_downloaded`,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                if (res.data.success) {
                    // setAllUserData(res.data.data);
                } else {
                    enqueueSnackbar("Error Updating is_downloaded, Try Again !!!", {
                        variant: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                return enqueueSnackbar("Something Went Wrong !!!", {
                    variant: "error",
                });
            });
    };

    const onSearchByClass = async () => {
        if (Object.values(allBtnData).includes('')) return enqueueSnackbar('Please Select All The Values !!!', { variant: 'error' })

        setSearchLoading(true);
        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/getmapped_data_byschoolid`, { school_id: allBtnData.school })
            .then(res => {
                if (res.data.success) {
                    let checkboxObj = {};
                    res.data.data?.[0]?.user_data?.forEach(user => {
                        checkboxObj = {
                            ...checkboxObj,
                            // [`standardPreviewCard_selectID_${user?.mapped_id}`]: user?.is_downloaded === 1 ? true : false
                            [`standardPreviewCard_selectID_${user?.mapped_id}`]: false
                        }
                    })

                    if (allBtnData.is_verified === '1') {
                        let updatedData = [];
                        for (let index = 0; index < res.data.data[0].user_data.length; index++) {
                          const element = res.data.data[0].user_data[index];
                          if (element.is_verified === 1) {
                              updatedData.push(element);
                          }
                        }
                        console.log(updatedData.length);
                        res.data.data[0].user_data = updatedData;
                        console.log(res.data.data)
                        setAllIDCards(res.data.data)
                      }
                      else if (allBtnData.is_verified === '0') {
                        let updatedData = [];
                        for (let index = 0; index < res.data.data[0].user_data.length; index++) {
                          const element = res.data.data[0].user_data[index];
                          if (element.is_verified === 0) {
                              updatedData.push(element);
                          }
                        }
                        console.log(updatedData.length);
                        res.data.data[0].user_data = updatedData;
                        console.log(res.data.data)
                        setAllIDCards(res.data.data)
                      }
                      else {
                        let updatedData = [];
                        for (let index = 0; index < res.data.data[0].user_data.length; index++) {
                          const element = res.data.data[0].user_data[index];
                            updatedData.push(element);
                        }
                        console.log(updatedData.length);
                        res.data.data[0].user_data = updatedData;
                        console.log(res.data.data)
                        setAllIDCards(res.data.data)
                      }

                    // setAllIDCards(res.data.data);
                    setAllIDCardsStorage(res.data.data);
                    setAllIDCardsCheckBox(checkboxObj);
                } else {
                    setAllIDCards([])
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setSearchLoading(false);
            })
            .catch(err => {
                console.log(err);
                setSearchLoading(false);
                return enqueueSnackbar('Something Went Wrong !!!', { variant: 'error' })
            })
    }

    const onDownloadAllClick = async () => {
        const zip = new JSZip();

        setDownloadAllLoading(true);

        if (allIDCards[0]?.id_card?.type == 1) {
            const oneSide = zip.folder("one-side");

            let oneSideCount = 1;
            for (let i = 0; i < allIDCards[0]?.user_data?.length; i++) {
                var frontNode = document.getElementById(`previewcustomCard-front-${allIDCards[0]?.user_data[i]?.mapped_id}`);

                await domtoimage
                    .toPng(frontNode, { cacheBust: true })
                    .then(function (dataUrl) {
                        var img = new Image();
                        img.src = dataUrl; // base64 img
                        const b64data = dataUrl.split(",")[1];

                        oneSide.file(`${oneSideCount}.png`, b64data, {
                            base64: true,
                        });
                    })
                    .catch(function (error) {
                        console.error("Error Downloading Image!", error);
                        setDownloadAllLoading(false);
                    });

                oneSideCount++;
            }

        } else {
            const bothSideFront = zip.folder("both-side/front");
            const bothSideBack = zip.folder("both-side/back");

            let bothSideCount = 1;
            for (let i = 0; i < allIDCards[0]?.user_data.length; i++) {
                var frontNode = document.getElementById(`previewcustomCard-front-${allIDCards[0]?.user_data[i]?.mapped_id}`);
                var backNode = document.getElementById(`previewcustomCard-back-${allIDCards[0]?.user_data[i]?.mapped_id}`);

                // let frontImageSuccess = await domtoimage.toPng(frontNode, { cacheBust: true })
                //     .then(function (dataUrl) {
                //         const b64data = dataUrl.split(",")[1];
                //         bothSideFront.file(`${bothSideCount}.png`, b64data, { base64: true });
                //         return true; // front image success
                //     })
                //     .catch(function (error) {
                //         console.error("Error Downloading Front Image!", error);
                //         setDownloadAllLoading(false);
                //         return false; // front image failed
                //     });
                // await domtoimage
                //     .toPng(frontNode, { cacheBust: true })
                //     .then(function (dataUrl) {
                //         var img = new Image();
                //         img.src = dataUrl; // base64 img
                //         const b64data = dataUrl.split(",")[1];

                //         bothSideFront.file(`${bothSideCount}.png`, b64data, {
                //             base64: true,
                //         });
                //     })
                //     .catch(function (error) {
                //         console.error("Error Downloading Image!", error);
                //         setDownloadAllLoading(false);
                //     });

                // Only download back image if front was successful
                // if (frontImageSuccess) {
                //     await domtoimage
                //         .toPng(backNode, { cacheBust: true })
                //         .then(function (dataUrl) {
                //             var img = new Image();
                //             img.src = dataUrl; // base64 img
                //             const b64data = dataUrl.split(",")[1];

                //             bothSideBack.file(`${bothSideCount}.png`, b64data, {
                //                 base64: true,
                //             });
                //         })
                //         .catch(function (error) {
                //             console.error("Error Downloading Image!", error);
                //             setDownloadAllLoading(false);
                //         });
                // }
                try {
                    // Download front image
                    const frontImageDataUrl = await domtoimage.toPng(frontNode, { cacheBust: true });
                    const frontImageData = frontImageDataUrl.split(",")[1];

                    // Download back image
                    const backImageDataUrl = await domtoimage.toPng(backNode, { cacheBust: true });
                    const backImageData = backImageDataUrl.split(",")[1];

                    // If both downloads succeed, add them to the zip
                    bothSideFront.file(`${bothSideCount}.png`, frontImageData, { base64: true });
                    bothSideBack.file(`${bothSideCount}.png`, backImageData, { base64: true });

                    // Increment count only if both images were successfully added
                    bothSideCount++;
                } catch (error) {
                    // If either download fails, log the error and skip to the next item
                    console.error("Error downloading image!", error);
                    setDownloadAllLoading(false);
                    // continue;
                }
                // bothSideCount++;
            }

        }

        const selectedSchoolName = allSchoolData?.filter(s => s.schoolid === allBtnData.school)?.[0]?.name
        zip.generateAsync({ type: "blob" }).then(content => {
            saveAs(content, `${selectedSchoolName} ID Cards.zip`);
            setDownloadAllLoading(false);
        });
        updateIsDownloaded();
    }

    const onDownloadSelected = async () => {
        const filteredObjArray = []
        Object.keys(allIDCardsCheckBox)?.filter(key => allIDCardsCheckBox[key] && filteredObjArray.push(key?.split('_')?.[2]));
        if (filteredObjArray?.length <= 0) return enqueueSnackbar('Please Select Images !!!', { variant: 'error' });

        const zip = new JSZip();

        setDownloadSelectedLoading(true)

        if (allIDCards[0]?.id_card?.type == 1) {
            const oneSide = zip.folder("one-side");

            let oneSideCount = 1;
            for (let i = 0; i < filteredObjArray?.length; i++) {
                var frontNode = document.getElementById(`previewcustomCard-front-${filteredObjArray[i]}`);

                await domtoimage
                    .toPng(frontNode, { cacheBust: true })
                    .then(function (dataUrl) {
                        var img = new Image();
                        img.src = dataUrl; // base64 img
                        const b64data = dataUrl.split(",")[1];

                        oneSide.file(`${oneSideCount}.png`, b64data, { base64: true });
                    })
                    .catch(function (error) {
                        console.error("Error Downloading Image!", error);
                        setDownloadSelectedLoading(false);
                    });

                oneSideCount++;
            }

        } else {
            const bothSideFront = zip.folder("both-side/front");
            const bothSideBack = zip.folder("both-side/back");

            let bothSideCount = 1;
            for (let i = 0; i < filteredObjArray?.length; i++) {
                var frontNode = document.getElementById(`previewcustomCard-front-${filteredObjArray[i]}`);
                var backNode = document.getElementById(`previewcustomCard-back-${filteredObjArray[i]}`);
                // let frontImageSuccess = await domtoimage.toPng(frontNode, { cacheBust: true })
                // .then(function (dataUrl) {
                //   const b64data = dataUrl.split(",")[1];
                //   bothSideFront.file(`${bothSideCount}.png`, b64data, { base64: true });
                //   return true; // front image success
                // })
                // .catch(function (error) {
                //   console.error("Error Downloading Front Image!", error);
                //   setDownloadAllLoading(false);
                //   return false; // front image failed
                // });
                // await domtoimage
                //     .toPng(frontNode, { cacheBust: true })
                //     .then(function (dataUrl) {
                //         var img = new Image();
                //         img.src = dataUrl; // base64 img
                //         const b64data = dataUrl.split(",")[1];

                //         bothSideFront.file(`${bothSideCount}.png`, b64data, { base64: true });
                //     })
                //     .catch(function (error) {
                //         console.error("Error Downloading Image!", error);
                //         setDownloadSelectedLoading(false);
                //     });

                // Only download back image if front was successful
                //   if (frontImageSuccess) {
                //         await domtoimage
                //             .toPng(backNode, { cacheBust: true })
                //             .then(function (dataUrl) {
                //                 var img = new Image();
                //                 img.src = dataUrl; // base64 img
                //                 const b64data = dataUrl.split(",")[1];

                //                 bothSideBack.file(`${bothSideCount}.png`, b64data, {
                //                     base64: true,
                //                 });
                //             })
                //             .catch(function (error) {
                //                 console.error("Error Downloading Image!", error);
                //                 setDownloadSelectedLoading(false);
                //             });
                //         }
                try {
                    // Download front image
                    const frontImageDataUrl = await domtoimage.toPng(frontNode, { cacheBust: true });
                    const frontImageData = frontImageDataUrl.split(",")[1];

                    // Download back image
                    const backImageDataUrl = await domtoimage.toPng(backNode, { cacheBust: true });
                    const backImageData = backImageDataUrl.split(",")[1];

                    // If both downloads succeed, add them to the zip
                    bothSideFront.file(`${bothSideCount}.png`, frontImageData, { base64: true });
                    bothSideBack.file(`${bothSideCount}.png`, backImageData, { base64: true });

                    // Increment count only if both images were successfully added
                    bothSideCount++;
                } catch (error) {
                    // If either download fails, log the error and skip to the next item
                    console.error("Error downloading image!", error);
                    setDownloadAllLoading(false);
                    // continue;
                }
                // bothSideCount++;
            }

        }

        const selectedSchoolName = allSchoolData?.filter(s => s.schoolid === allBtnData.school)?.[0]?.name
        zip.generateAsync({ type: "blob" }).then(content => {
            saveAs(content, `${selectedSchoolName} Selected ID Cards.zip`);
            onSendDownloadedSchoolsToServer(filteredObjArray);
        });
        updateIsDownloaded();
    }

    const onSendDownloadedSchoolsToServer = async (arr) => {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/updatedownloadstatus`, { ids: arr })
            .then(res => {
                if (res.data.success) {
                    console.log(res.data.result);
                } else {
                    enqueueSnackbar('Error Uploading Schools Array, Try Again !!!', { variant: 'error' })
                }
                setDownloadSelectedLoading(false);
            })
            .catch(err => {
                console.log(err);
                setDownloadSelectedLoading(false);
                return enqueueSnackbar('Something Went Wrong !!!', { variant: 'error' })
            })
    }

    // const onDownloadNotDownloaded = async () => {
    //     const notdownloadedArr = []
    //     allIDCards[0]?.user_data?.forEach(card => card?.is_downloaded !== 1 && notdownloadedArr?.push(card?.mapped_id));
    //     if (notdownloadedArr?.length <= 0) return enqueueSnackbar('All Images are Downloaded !!!', { variant: 'error' });

    //     const zip = new JSZip();

    //     setDownloadNotDownloadedLoading(true)

    //     if (allIDCards[0]?.id_card?.type == 1) {
    //         const oneSide = zip.folder("one-side");

    //         let oneSideCount = 1;
    //         for (let i = 0; i < notdownloadedArr?.length; i++) {
    //             var frontNode = document.getElementById(`previewcustomCard-front-${notdownloadedArr[i]}`);

    //             await domtoimage
    //                 .toPng(frontNode, { cacheBust: true })
    //                 .then(function (dataUrl) {
    //                     var img = new Image();
    //                     img.src = dataUrl; // base64 img
    //                     const b64data = dataUrl.split(",")[1];

    //                     oneSide.file(`${oneSideCount}.png`, b64data, { base64: true });
    //                 })
    //                 .catch(function (error) {
    //                     console.error("Error Downloading Image!", error);
    //                     setDownloadNotDownloadedLoading(false);
    //                 });

    //             oneSideCount++;
    //         }

    //     } else {
    //         const bothSideFront = zip.folder("both-side/front");
    //         const bothSideBack = zip.folder("both-side/back");

    //         let bothSideCount = 1;
    //         for (let i = 0; i < notdownloadedArr?.length; i++) {
    //             var frontNode = document.getElementById(`previewcustomCard-front-${notdownloadedArr[i]}`);
    //             var backNode = document.getElementById(`previewcustomCard-back-${notdownloadedArr[i]}`);

    //             await domtoimage
    //                 .toPng(frontNode, { cacheBust: true })
    //                 .then(function (dataUrl) {
    //                     var img = new Image();
    //                     img.src = dataUrl; // base64 img
    //                     const b64data = dataUrl.split(",")[1];

    //                     bothSideFront.file(`${bothSideCount}.png`, b64data, { base64: true });
    //                 })
    //                 .catch(function (error) {
    //                     console.error("Error Downloading Image!", error);
    //                     setDownloadNotDownloadedLoading(false);
    //                 });

    //             await domtoimage
    //                 .toPng(backNode, { cacheBust: true })
    //                 .then(function (dataUrl) {
    //                     var img = new Image();
    //                     img.src = dataUrl; // base64 img
    //                     const b64data = dataUrl.split(",")[1];

    //                     bothSideBack.file(`${bothSideCount}.png`, b64data, { base64: true });
    //                 })
    //                 .catch(function (error) {
    //                     console.error("Error Downloading Image!", error);
    //                     setDownloadNotDownloadedLoading(false);
    //                 });

    //             bothSideCount++;
    //         }

    //     }

    //     const selectedSchoolName = allSchoolData?.filter(s => s.schoolid === allBtnData.school)?.[0]?.name
    //     zip.generateAsync({ type: "blob" }).then(content => {
    //         saveAs(content, `${selectedSchoolName} Not Downloaded ID Cards.zip`);
    //         // onSendDownloadedSchoolsToServer(notdownloadedArr);
    //     });
    // }

    const onCardTypeChange = (e) => {
        let idCardsArr = [];
        // const allIDCardsCheckBoxObj = { ...allIDCardsCheckBox }

        switch (e.target.value) {
            case 'all':
                idCardsArr = [...allIDCardsStorage?.[0]?.user_data]
                break;

            case 'downloaded':
                idCardsArr = allIDCardsStorage?.[0]?.user_data?.filter(c => c?.is_downloaded === 1)
                break;

            case 'not_downloaded':
                idCardsArr = allIDCardsStorage?.[0]?.user_data?.filter(c => c?.is_downloaded !== 1)
                break;

            default:
                idCardsArr = [...allIDCardsStorage?.[0]?.user_data]
                break;
        }

        let checkboxObj = {};
        idCardsArr?.forEach(user => {
            checkboxObj = { ...checkboxObj, [`standardPreviewCard_selectID_${user?.mapped_id}`]: false }
        })

        setAllIDCardsCheckBox(checkboxObj);
        setCardType(e.target.value);
        setAllIDCards([{ ...allIDCardsStorage?.[0], user_data: idCardsArr }]);
    }


    return (
        <StandardPageWrapper
            header="Download By School"
            btns={
                <>
                    {/* <CustomMuiSelect
                        label="Select School"
                        val={allBtnData.school}
                        onChange={(e) => setAllBtnData({ ...allBtnData, school: e.target.value })}
                        width={150}
                        size="small"
                        disabled={searchLoading}
                    >
                        {allSchoolData?.map((c, i) => (
                            <MenuItem value={c?.schoolid} key={i}>
                                {c?.name}
                            </MenuItem>
                        ))}
                    </CustomMuiSelect> */}
                    <Autocomplete
                        options={allSchoolData || []}
                        getOptionLabel={(option) => option?.name || ""}
                        value={allSchoolData.find((school) => school.schoolid === allBtnData.school) || null}
                        onChange={(event, newValue) => setAllBtnData({ ...allBtnData, school: newValue?.schoolid || "" })}
                        disabled={searchLoading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select School"
                                size="small"
                                sx={{
                                    width: 150,
                                    backgroundColor: "white", // Setting the background color to white
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'lightgray', // Optional: Adjust border color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'gray', // Optional: Change border color on hover
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'darkgreen', // Optional: Focus border color
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'black', // Default label color (before hover/focus)
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: 'black', // Label color when focused
                                    },
                                    '& .MuiInputLabel-root:hover': {
                                        color: 'black', // Label color when hovered
                                    },
                                }}
                                // style={{ width: 150 }}
                                disabled={searchLoading}
                            />
                        )}
                    />
                    <CustomMuiSelect
                        label="Is Verified"
                        val={allBtnData.is_verified}
                        onChange={fetchAsIsVerified}
                        // onChange={(e) =>
                        // setAllBtnData({ ...allBtnData, is_verified: e.target.value })
                        // }
                        width={150}
                        size="small"
                        sx={{ marginRight: 1.5 }}
                        disabled={searchLoading}
                    >
                        <MenuItem
                            value="all"
                            key="2"
                        >All</MenuItem>
                        <MenuItem
                            value="1"
                            key="1"
                        >Verified</MenuItem>
                        <MenuItem
                            value="0"
                            key="0"
                        >Not Verified</MenuItem>
                    </CustomMuiSelect>
                    <StandardButton
                        color="white"
                        variant="contained"
                        style={{ background: "darkgreen !important", marginLeft: '15px !important' }}
                        onClick={onSearchByClass}
                        label={<SearchIcon />}
                        loading={searchLoading}
                    />

                    {allIDCards?.length > 0 && (
                        <>
                            <StandardButton
                                variant="contained"
                                onClick={onDownloadAllClick}
                                loading={downloadAllLoading}
                                style={{ background: "darkgreen !important" }}
                                label="Download All"
                            />

                            <StandardButton
                                variant="contained"
                                onClick={onDownloadSelected}
                                loading={downloadSelectedLoading}
                                style={{ background: "#0d7a8e !important" }}
                                label="Download Selected"
                            />

                            {/* <StandardButton
                                variant="contained"
                                onClick={onDownloadNotDownloaded}
                                loading={downloadNotDownloadedLoading}
                                style={{ background: "#032025 !important" }}
                                label="Not Downloaded"
                            /> */}
                            <CustomMuiSelect label="Select Card Type" val={cardType} onChange={onCardTypeChange}
                                width={140} size="small" disabled={searchLoading}
                            >
                                <MenuItem value='all'>All</MenuItem>
                                <MenuItem value='downloaded'>Downloaded</MenuItem>
                                <MenuItem value='not_downloaded'>Not Downloaded</MenuItem>
                            </CustomMuiSelect>
                        </>
                    )}
                </>
            }
        >
            <div id="preview">
                <div className="previewCard_cont">
                    {searchLoading ? (
                        <CircularProgress
                            size={30}
                            sx={{ display: "block", margin: "auto", color: "#4b49ac" }}
                        />
                    ) : (
                        <>
                            {allIDCards?.[0]?.user_data?.map((u, i) => (
                                <div className='standardPreviewCard_parent' key={u?.mapped_id} >
                                    <Checkbox
                                        // disabled={u?.is_downloaded ? true : false}
                                        checked={allIDCardsCheckBox?.[`standardPreviewCard_selectID_${u?.mapped_id}`]}
                                        onChange={(e) => setAllIDCardsCheckBox({ ...allIDCardsCheckBox, [`standardPreviewCard_selectID_${u?.mapped_id}`]: e.target.checked })}
                                    />

                                    <StandardPreviewCardDownload
                                        custom_uid={u?.mapped_id}
                                        cardData={allIDCards?.[0]?.id_card}
                                        userData={u}
                                    />
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </StandardPageWrapper>
    );
}

export default DownloadBySchool
