import "./Classroom.css";
import { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Button,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";
// Components
import StandardPageWrapper from "../../../components/StandardPageWrapper/StandardPageWrapper";
import StandardOutlinedTextfield from "../../../components/StandardOutlinedTextfield/StandardOutlinedTextfield";
import StandardMuiDialogWrapper from "../../../components/StandardMuiDialogWrapper/StandardMuiDialogWrapper";
import ReactCustomTable from "../../../components/ReactCustomTable/ReactCustomTable";
// ICONs & SVGs
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Delete, Edit, ArrowForward } from "@mui/icons-material";

import POPUploadCSV from "../../../components/POPUploadCSV";
import { saveAs } from "file-saver";

function Classroom() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isDisabled, setIsDisabled] = useState(false);
  const [allCLassData, setAllCLassData] = useState([]);
  const [allClassDataLoading, setAllClassDataLoading] = useState(true);
  const [addClassData, setAddClassData] = useState({
    standard: "",
    division: "",
  });
  // Teacher
  const [addTeacherData, setAddTeacherData] = useState({
    first_name: "",
    phone_no: "",
    classid: "",
  });
  const [addTeacherDataWithClass, setAddTeacherDataWithClass] = useState({
    first_name: "",
    phone: "",
    standard: "",
    division: "",
  });
  // Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogBtnLoading, setDialogBtnLoading] = useState(false);
  const [openTeacherDialog, setOpenTeacherDialog] = useState(false);
  const [openTeacherDialogLoading, setOpenTeacherDialogLoading] =
    useState(false);
  const [openTeacherWithCLassDialog, setOpenTeacherWithCLassDialog] =
    useState(false);
  const [
    openTeacherWithCLassDialogLoading,
    setOpenTeacherWithCLassDialogLoading,
  ] = useState(false);
  // Delete
  const [deleteID, setDeleteID] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  // Edit
  const [editActive, setEditActive] = useState(false);
  const [editId, setEditId] = useState("");

  useEffect(() => {
    if (!state) return navigate("/schools");

    fetchAllClass();
  }, []);

  const download_sample = () => {
    saveAs(
      "https://icard-holder.s3.ap-south-1.amazonaws.com/excel_sheet/bulk-teacher-with-class.xlsx"
      , "Teacher-with-class_Template.xlsx"
    );
  }

  const fetchAllClass = async () => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/getclassesbyschoolid`, {
        schoolid: state.schoolid,
      })
      .then((res) => {
        if (res.data.success) {
          setAllCLassData(res.data.data);
        } else {
          enqueueSnackbar("Error Fetching Class, Try Again !!!", {
            variant: "error",
          });
        }
        setAllClassDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAllClassDataLoading(false);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const onAddClass = async () => {
    // if (Object.values(addClassData)?.includes("")) return enqueueSnackbar("Please Fill All The Details !!!", { variant: "error" });
    if (!state?.schoolid) return enqueueSnackbar("No School Found !!!", { variant: "error" });

    setDialogBtnLoading(true);

    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/addclass`, {
        schoolid: state.schoolid,
        ...addClassData,
      })
      .then((res) => {
        if (res.data.success) {
          setAllCLassData([...allCLassData, res.data.result]);
          enqueueSnackbar("Class Added Successfully !!!", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
        setOpenDialog(false);
        setDialogBtnLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDialogBtnLoading(false);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const onEdit = (e, data) => {
    e.stopPropagation();
    if (!data.classid)
      return enqueueSnackbar("Class ID Not Found !!!", { variant: "error" });
    setEditId(data.classid);
    setOpenDialog(true);
    setEditActive(true);
    setAddClassData({ standard: data.standard, division: data.division });
  };

  const onSaveEdit = async () => {
    // if (Object.values(addClassData)?.includes("")) return enqueueSnackbar("Please Fill All The Details !!!", { variant: "error" });
    if (!editId) return enqueueSnackbar("Class ID Not Found !!!", { variant: "error" });

    setDialogBtnLoading(true);

    let body = { ...addClassData, classid: editId };
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/updateclass`, body)
      .then((res) => {
        if (res.data.success) {
          const editDataIndex = allCLassData.findIndex(
            (i) => i.classid == editId
          );
          if (editDataIndex === -1)
            return enqueueSnackbar("Error Updating Class !!!", {
              variant: "success",
            });

          let arr = [...allCLassData];
          arr[editDataIndex] = body;
          setAllCLassData(arr);
          enqueueSnackbar("Class Updated Successfully !!!", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
        setOpenDialog(false);
        setDialogBtnLoading(false);
        setEditId("");
      })
      .catch((err) => {
        console.log(err);
        setDialogBtnLoading(false);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const onDelete = async (e, data) => {
    e.stopPropagation();
    if (!data.classid)
      return enqueueSnackbar("Class ID Not Found !!!", { variant: "error" });
    setDeleteID(data.classid);
    setDeleteLoading(true);

    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/deleteclass`, {
        classid: data.classid,
      })
      .then((res) => {
        if (res.data.success) {
          setAllCLassData(
            allCLassData?.filter((s) => s?.classid != data?.classid)
          );
          enqueueSnackbar("Class Deleted Successfully !!!", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Error Deleting Class, Try Again !!!", {
            variant: "error",
          });
        }
        setDeleteID("");
        setDeleteLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDeleteLoading(false);
        setDeleteID("");
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const onNavigatetoUser = (e, data) =>
    navigate(`/schools/class/${data.classid}`, { state: data });

  const onExited = () => {
    setAddClassData({ standard: "", division: "" });
    setEditActive(false);
  };

  const onAddTeacher = async () => {
    // if (Object.values(addTeacherData)?.includes("")) return enqueueSnackbar("Please Fill All The Details !!!", { variant: "error" });
    if (!state?.schoolid) return enqueueSnackbar("No School Found !!!", { variant: "error" });

    setOpenTeacherDialogLoading(true);

    const body = {
      ...addTeacherData,
      schoolid: state.schoolid,
      role: "teacher",
      is_verified: 1,
    };
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/signup`, body)
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("Teacher Added Successfully !!!", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
        setOpenTeacherDialog(false);
        setOpenTeacherDialogLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenTeacherDialogLoading(false);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const onAddTeacherWithClass = async () => {
    // if (Object.values(addTeacherDataWithClass)?.includes("")) return enqueueSnackbar("Please Fill All The Details !!!", {variant: "error"});
    if (!state?.schoolid)
      return enqueueSnackbar("No School Found !!!", { variant: "error" });

    setOpenTeacherWithCLassDialogLoading(true);

    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/addclasswithclassteacher`, {
        ...addTeacherDataWithClass,
        schoolid: state.schoolid,
        last_name: "",
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          // setAllCLassData([...allCLassData, res.data.result])
          fetchAllClass();

          enqueueSnackbar("Teacher Added Successfully !!!", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
        setOpenTeacherWithCLassDialog(false);
        setOpenTeacherWithCLassDialogLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenTeacherWithCLassDialogLoading(false);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const tableActions = [
    {
      icon: <Delete sx={{ cursor: "pointer" }} color="error" />,
      onClick: onDelete,
      btnType: "del",
    },
    {
      icon: <Edit sx={{ cursor: "pointer", color: "#7978e9" }} />,
      onClick: onEdit,
    },
    {
      icon: <ArrowForward sx={{ cursor: "pointer" }} color="primary" />,
      onClick: onNavigatetoUser,
    },
  ];

  return (
    <>
      <POPUploadCSV
        isDialog={isDisabled}
        onClose={setIsDisabled}
      />
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        sx={{ marginBottom: 1 }}
      >
        <Typography color="grey">Schools</Typography>
        <Typography sx={{ color: "#7978e9", fontWeight: "bold" }}>
          Class
        </Typography>
      </Breadcrumbs>
      <StandardPageWrapper
        header={state?.name ? `${state.name} | Classes` : ""}
        btns={
          <>
            <Button
              variant="contained"
              onClick={() => download_sample()}
            >
              Download Template
            </Button>
            <Button
              variant="contained"
              onClick={() => setIsDisabled(!isDisabled)}
            >
              Bulk Addition of Teacher With Class
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenTeacherWithCLassDialog(true)}
            >
              Add Teacher With Class
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenTeacherDialog(true)}
            >
              Add Teacher
            </Button>
            <Button variant="contained" onClick={() => setOpenDialog(true)}>
              Add Class
            </Button>
          </>
        }
      >
        <div id="classroom">
          <ReactCustomTable
            data={allCLassData}
            tableColumns={tableColumns}
            loading={allClassDataLoading}
            tableActions={tableActions}
            actionsColumnWidth={120}
            deleteID={deleteID}
            deleteLoading={deleteLoading}
            rowDataDeleteKey="classid"
            circularColor="#d32f2f"
          />
        </div>

        {/* ADD-UPDATE Dialog  */}
        <StandardMuiDialogWrapper
          open={openDialog}
          setOpen={setOpenDialog}
          onExited={onExited}
          dialogTitle={`${editActive ? "Update" : "Add"} Class`}
          onSaveBtnClick={editActive ? onSaveEdit : onAddClass}
          saveBtnLabel={editActive ? "Update" : "Save"}
          saveBtnLoading={dialogBtnLoading}
        >
          <StandardOutlinedTextfield
            label="Class Standard"
            name="standard"
            val={addClassData.standard}
            setVal={setAddClassData}
            spreadData={addClassData}
          />
          <StandardOutlinedTextfield
            label="Class Division"
            name="division"
            val={addClassData.division}
            setVal={setAddClassData}
            spreadData={addClassData}
          />
        </StandardMuiDialogWrapper>

        {/* Add Teacher */}
        <StandardMuiDialogWrapper
          open={openTeacherDialog}
          setOpen={setOpenTeacherDialog}
          onExited={() => { }}
          dialogTitle="Add Teacher"
          onSaveBtnClick={onAddTeacher}
          saveBtnLabel="Save"
          saveBtnLoading={openTeacherDialogLoading}
        >
          <StandardOutlinedTextfield
            label="Name"
            name="first_name"
            val={addTeacherData.first_name}
            setVal={setAddTeacherData}
            spreadData={addTeacherData}
          />
          <StandardOutlinedTextfield
            label="Phone Number"
            type="number"
            name="phone_no"
            val={addTeacherData.phone_no}
            setVal={setAddTeacherData}
            spreadData={addTeacherData}
          />

          <TextField
            fullWidth
            select
            label="Select Class"
            value={addTeacherData.classid}
            onChange={(e) =>
              setAddTeacherData({ ...addTeacherData, classid: e.target.value })
            }
            sx={{ marginTop: 1.5 }}
          >
            {allCLassData?.map((c, i) => (
              <MenuItem value={c?.classid} key={i}>
                {c?.standard} - {c?.division}
              </MenuItem>
            ))}
          </TextField>
        </StandardMuiDialogWrapper>

        {/* Add Teacher With Class*/}
        <StandardMuiDialogWrapper
          open={openTeacherWithCLassDialog}
          setOpen={setOpenTeacherWithCLassDialog}
          onExited={() => { }}
          dialogTitle="Add Teacher With Class"
          onSaveBtnClick={onAddTeacherWithClass}
          saveBtnLabel="Save"
          saveBtnLoading={openTeacherWithCLassDialogLoading}
        >
          <StandardOutlinedTextfield
            label="Name"
            name="first_name"
            val={addTeacherDataWithClass.first_name}
            setVal={setAddTeacherDataWithClass}
            spreadData={addTeacherDataWithClass}
          />
          <StandardOutlinedTextfield
            label="Phone Number"
            type="number"
            name="phone"
            val={addTeacherDataWithClass.phone}
            setVal={setAddTeacherDataWithClass}
            spreadData={addTeacherDataWithClass}
          />
          <StandardOutlinedTextfield
            label="Standard"
            name="standard"
            val={addTeacherDataWithClass.standard}
            setVal={setAddTeacherDataWithClass}
            spreadData={addTeacherDataWithClass}
          />
          <StandardOutlinedTextfield
            label="Division"
            name="division"
            val={addTeacherDataWithClass.division}
            setVal={setAddTeacherDataWithClass}
            spreadData={addTeacherDataWithClass}
          />
        </StandardMuiDialogWrapper>
        {/*  */}
      </StandardPageWrapper>
    </>
  );
}

export default Classroom;

const tableColumns = [
  {
    label: "Class ID",
    key: "classid",
    width: 100,
    fixed: true,
    align: "center",
  },
  {
    label: "Standard",
    key: "standard",
    // width: 200,
    fixed: false,
    align: "center",
    flexGrow: 1,
  },
  {
    label: "Division",
    key: "division",
    // width: 155,
    fixed: false,
    align: "center",
    flexGrow: 1,
  },
];
