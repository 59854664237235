import { useState, useEffect } from 'react';
import './DownloadByExcel.css';
import { saveAs } from 'file-saver';
import { Button, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as XLSX from 'xlsx';
import domtoimage from "dom-to-image-chrome-fix-retina";
import JSZip from 'jszip';
// Components
import StandardPageWrapper from '../../components/StandardPageWrapper/StandardPageWrapper';
import StandardPreviewCard from '../../components/StandardPreviewCard/StandardPreviewCard';

function DownloadByExcel() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state } = useLocation();

  // XCEL
  const [xcelData, setXcelData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [downloadAllLoading, setDownloadAllLoading] = useState(false);
  // FETCHED ID CARD
  const [idExcelData, setIdExcelData] = useState([]);
  const [idCardData, setIdCardData] = useState({});
  const [idCardDataLoading, setIdCardDataLoading] = useState(true);
  // 
  const [createAllLoading, setCreateAllLoading] = useState(false);


  useEffect(() => {
    if (!state?.idCard_id) return navigate('/idcards', { replace: true });
    fetchIdCard();
  }, [])

  const fetchIdCard = async () => {
    await axios.post(`${process.env.REACT_APP_BASE_URL}/user/getidcard`, { card_id: state?.idCard_id })
      .then((res) => {
        if (res.data.success) {
          const idCardData = res.data.data
          // EXCEL Array
          const frontImgObjects = idCardData.view_data.id_front.image_objects.map(t => t.tag);
          const frontTextObjects = idCardData.view_data.id_front.text_objects.map(t => t.tag);

          let backImgObjects = [], backTextObjects = [];
          if (idCardData.type == 2) {
            backImgObjects = idCardData.view_data.id_back.image_objects.map(t => t.tag);
            backTextObjects = idCardData.view_data.id_back.text_objects.map(t => t.tag);
          }

          setIdExcelData([...frontImgObjects, ...frontTextObjects, ...backImgObjects, ...backTextObjects])
          setIdCardData(idCardData);
        } else {
          enqueueSnackbar("Error Fetching Class, Try Again !!!", { variant: "error" });
        }
        setIdCardDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIdCardDataLoading(false);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };


  const createIdCards = async () => {
    const fData = new FormData();
    fData.append("icard_id", state?.idCard_id);
    fData.append("file", selectedFile);
    setCreateAllLoading(true);
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/importusers`, fData
      )
      .then((res) => {
        if (res.data.success) {
          setCreateAllLoading(false);

          enqueueSnackbar("ID cards created successfully !!!", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setCreateAllLoading(false);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const handleChange = (e) => {
    const f = e.target.files[0]
    if (!f?.size) return setXcelData([]); setSelectedFile(null);

    setSelectedFile(f);
    var name = f.name;
    const reader = new FileReader();
    reader.onload = (evt) => { // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // Get as JSON Obj
      var xcelArrObj = XLSX.utils.sheet_to_json(ws)
      setXcelData(xcelArrObj);
      // console.log(xcelArrObj);

      /* Convert array of arrays */
      // const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      // console.log("Data>>>" + data);
      /* Update state */
    };
    reader.readAsBinaryString(f);
  }

  const onDownloadAllClick = () => {
    const zip = new JSZip();
    setDownloadAllLoading(true);

    xcelData.forEach((xcel, index) => {
      var node = document.getElementById(`previewcustomCard-${index}`);

      domtoimage
        .toPng(node, { cacheBust: true })
        .then(function (dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          const b64data = dataUrl.split(",")[1];

          zip.file(`Icard-${index + 1}.png`, b64data, { base64: true });

          if (index + 1 == xcelData.length) {
            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, "ICard.zip");
              setDownloadAllLoading(false);
            });
          }
        })
        .catch(function (error) {
          console.error("Error Downloading Image!", error);
          setDownloadAllLoading(false);
        });

    })
  }

  const onExcelDownload = () => {
    const standardHeaders = ['first_name_student', 'phone_no_student', 'class_id_student', 'school_id_student']
    const excelHeaders = [...standardHeaders, ...idExcelData];

    var worksheet = XLSX.utils.json_to_sheet([{ school_id_student: idCardData?.schoolid }], { header: excelHeaders });
    const wb = { Sheets: { 'ID SOLUTIONS ID-CARD': worksheet }, SheetNames: ['ID SOLUTIONS ID-CARD'] };
    XLSX.writeFile(wb, 'ID SOLUTIONS.xls');
  }

  return (
    <StandardPageWrapper
      header="Download Via Excel"
      btns={
        <>
          <Button
            variant="contained"
            component="label"
            disabled={idCardDataLoading}
          >
            {selectedFile ? selectedFile?.name : "Choose File"}
            <input
              id="csv_selector"
              type="file"
              onChange={handleChange}
              hidden
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </Button>
          {!!idCardData && (
            <Button
              variant="contained"
              component="label"
              disabled={idCardDataLoading}
              color="secondary"
              className="download_by_excel_csvBtn"
              onClick={onExcelDownload}
            >
              Download Excel
            </Button>
          )}
          {xcelData?.length > 0 && (
            <Button
              variant="contained"
              onClick={onDownloadAllClick}
              disabled={downloadAllLoading}
              color="success"
              sx={{ marginLeft: "16px !important" }}
            >
              {downloadAllLoading ? (
                <CircularProgress sx={{ color: "#fff" }} size={22} />
              ) : (
                "Download All"
              )}
            </Button>
          )}
          {xcelData?.length > 0 && (
            <Button
              variant="contained"
              onClick={createIdCards}
              disabled={downloadAllLoading}
              color="success"
              sx={{ marginLeft: "16px !important" }}
            >
              {createAllLoading ? (
                <CircularProgress sx={{ color: "#fff" }} size={22} />
              ) : (
                "Create ID Cards"
              )}
            </Button>
          )}
        </>
      }
    >
      {xcelData?.length > 0 && (
        <div id="download_by_excel">
          <div className="download_by_excel_cont">
            {xcelData?.map((x, i) => (
              <StandardPreviewCard
                key={i}
                index={i}
                cardData={idCardData}
                userData={{ user_details: x }}
                hideImgs
              />
            ))}
          </div>
        </div>
      )}
    </StandardPageWrapper>
  );
}

export default DownloadByExcel
