import './CustomMuiSelect.css';
import { TextField } from '@mui/material'

function CustomMuiSelect({ label, val, onChange, width, size, sx, disabled, children }) {
    return (
        <TextField select label={label} value={val} onChange={onChange} className='custom_muiSelect_input'
            sx={{ ...sx, width: width }}
            variant='filled' size={size || 'medium'}
            disabled={disabled}
        >
            {children}
        </TextField>
    )
}

export default CustomMuiSelect