import './StandardPreviewCardDownload.css';
import { Card, CardActionArea } from '@mui/material';
import GoogleFontLoader from 'react-google-font-loader';

function StandardPreviewCardDownload({ custom_uid, cardData, userData, hideImgs, onClick }) {

  const getFonts = () => {
    if (Object.keys(cardData).length <= 0) return;

    const arr1 = [];
    cardData?.view_data?.id_front?.text_objects?.forEach(font => arr1.push(font.fontFamily));

    const arr2 = [];
    if (cardData.type == 2) cardData?.view_data?.id_back?.text_objects?.forEach(font => arr2.push(font.fontFamily));

    const FFSet = new Set([...arr1, ...arr2]);

    const fontsArr = [];
    FFSet.forEach(font => fontsArr.push({ font: font?.split(',')[0], weights: [400, 600, 700], }))

    return fontsArr;
  }

  const textTransformSelector = (type) => {
    let textType = null;

    switch (type) {
      case 'camelcase':
        return textType = 'capitalize';

      case 'capitalize':
        return textType = 'uppercase';

      default:
        return textType = 'inherit';
    }
  }

  return (
    <Card
      className="standardPreviewCard"
      id={`standardPreviewCard_selectID_${custom_uid}`}
      style={{
        margin: cardData?.type == 1 ? "auto" : "initial",
        maxWidth: cardData?.type == 1 ? "max-content" : "initial",
      }}
    >
      <CardActionArea>
        <div
          className="previewcustomCard_cont"
          onClick={onClick ? onClick : () => { }}
          style={{ display: cardData?.type == 2 ? "grid" : "block" }}
        >
          {/* FRONT CARD */}
          <section
            className="previewcustomCard_front"
            id={`previewcustomCard-front-${custom_uid}`}
            style={{
              background: `url(${cardData?.document_path_front}) no-repeat center/contain`,
              height: `${cardData?.view_data?.id_front?.height}px`,
              width: `${cardData?.view_data?.id_front?.width}px`,
              position: "relative",
              margin: "auto",
            }}
          >
            {!hideImgs &&
              cardData?.view_data?.id_front?.image_objects?.map((img, i) => (
                <img
                  key={i}
                  src={
                    userData ? userData?.user_details?.[img.tag] : img?.src
                  }
                  style={{
                    ...img,
                    position: "absolute",
                    border: `${img?.strokeWidth}px solid ${img?.stroke}`,
                  }}
                />
              ))}
            {cardData?.view_data?.id_front?.text_objects?.map((t, i) => (
              <div key={i} style={{ ...t, position: "absolute", fontWeight: t?.fontStyle, textTransform: textTransformSelector(t?.text_format) }}>
                <div style={{ color: t?.fill, margin: 0, lineHeight: 1 }}>
                  {t?.prefix && `${t?.prefix} `}

                  {userData ? (
                    <>{userData?.user_details?.[t?.tag]}</>
                  ) : (
                    <>{t.text}</>
                  )}
                </div>
              </div>
            ))}
          </section>

          {/* BACK CARD */}
          {cardData?.type == 2 && (
            <section
              className="previewcustomCard_back"
              id={`previewcustomCard-back-${custom_uid}`}
              style={{
                background: `url(${cardData?.document_path_back}) no-repeat center/contain`,
                height: `${cardData?.view_data?.id_back?.height}px`,
                width: `${cardData?.view_data?.id_back?.width}px`,
                position: "relative",
                margin: "auto",
              }}
            >
              {!hideImgs &&
                cardData?.view_data?.id_back?.image_objects?.map((img, i) => (
                  <img
                    key={i}
                    src={
                      userData ? userData?.user_details?.[img.tag] : img?.src
                    }
                    style={{
                      ...img,
                      position: "absolute",
                      border: `${img?.strokeWidth}px solid ${img?.stroke}`,
                    }}
                  />
                ))}
              {cardData?.view_data?.id_back?.text_objects?.map((t, i) => (
                <div key={i} style={{ ...t, position: "absolute", fontWeight: t?.fontStyle, textTransform: textTransformSelector(t?.text_format) }}>
                  <div style={{ color: t?.fill, margin: 0, lineHeight: 1 }}>
                    {t?.prefix && `${t?.prefix} `}

                    {userData ? (
                      <>{userData?.user_details?.[t?.tag]}</>
                    ) : (
                      <>{t.text}</>
                    )}
                  </div>
                </div>
              ))}
            </section>
          )}
        </div>
      </CardActionArea>

      <GoogleFontLoader
        fonts={getFonts() || [{ font: "Roboto", weights: [400, 600] }]}
        subsets={["cyrillic-ext", "greek"]}
      />
    </Card>
  );
}

export default StandardPreviewCardDownload