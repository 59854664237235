import ImageIcon from "@mui/icons-material/Image";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { Button, Card, Input, TextField } from "@mui/material";
import Slider from "@mui/material/Slider";
import domtoimage from "dom-to-image-chrome-fix-retina";
import { MuiColorInput } from "mui-color-input";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Draggable from "react-draggable";
import GoogleFontLoader from "react-google-font-loader";
import StandardMuiDialogWrapper from "../../components/StandardMuiDialogWrapper/StandardMuiDialogWrapper";
import StandardOutlinedTextfield from "../../components/StandardOutlinedTextfield/StandardOutlinedTextfield";
import "./CreateIdCard.css";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Col from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import ReactCrop from "react-image-crop";
import { Resizable, ResizableBox } from "react-resizable";
import img_bg from "../../img/back.jpg";

import { height } from "@mui/system";
import { render } from "react-dom";
import { Rnd } from "react-rnd";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Row } from "rsuite";

import DeleteIcon from "@mui/icons-material/Delete";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AddIcon from "@mui/icons-material/Add";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { AccountBoxRounded, TextIncreaseRounded } from "@mui/icons-material";

export const CreateIdCard = () => {
  const [size, setSize] = useState(50);
  const [radius, setRadius] = useState(0);
  const [border, setBorder] = useState(0);
  const [bold, setBold] = useState("bold");
  const [color, setColor] = useState("#000000");
  const [colorBorder, setColorBorder] = useState("#000000");
  const [fontFamily, setFontFamily] = useState("Roboto, sans-serif");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [previewMode, setPreviewMode] = useState(false);
  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const [currentCardSide, setCurrentCardSide] = useState("id_front");
  const [src, setSrc] = useState({ id_front: "", id_back: "" });
  const [crop, setCrop] = useState(null);
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const [checked, setChecked] = useState(true);
  const [images, setImages] = useState({ id_front: null, id_back: null });
  const [showAddText, setShowAddText] = useState(false);
  const [showAddImage, setShowAddImage] = useState(false);
  const [showUpdateText, setShowUpdateText] = useState(false);
  const [showUpdateImage, setShowUpdateImage] = useState(false);
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [idCardData, setIdCardData] = useState([]);
  const [idCardDataLoading, setIdCardDataLoading] = useState(true);
  const [allSchoolData, setAllSchoolData] = useState([]);
  const [isList, setIsList] = useState(false);
  const [currentTextObj, setCurrentTextObj] = useState({});
  const [currentImgObj, setCurrentImgObj] = useState({});

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (state) {
      fetchIdCard();
    }
    fetchAllSchools();
  }, []);

  const fetchAllSchools = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/user/getallschool`)
      .then((res) => {
        if (res.data.success) {
          setAllSchoolData(res.data.data);
        } else {
          enqueueSnackbar("Error Fetching Schools, Try Again !!!", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const fetchIdCard = async () => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/getidcard`, {
        card_id: state.id,
      })
      .then((res) => {
        if (res.data.success) {
          setIdCardData(res.data.data);
          // console.log(res.data.data);
          setCardData({
            ...cardData,
            type: state.type,
            classid: res.data.data.classid,
            schoolid: res.data.data.schoolid,
            name: state.name,
            width: res.data.data.view_data.id_front.width,
            height: res.data.data.view_data.id_front.height,
          });
          setTestObj(res.data.data.view_data);
        } else {
          enqueueSnackbar("Error Fetching Class, Try Again !!!", {
            variant: "error",
          });
        }
        setIdCardDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIdCardDataLoading(false);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const onAddClass = async () => {
    setOpenDialog(!openDialog);
  };

  const handleTextChange = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide]["text_objects"][index] = {
        ...newBoxes[currentCardSide]["text_objects"][index],
        text: e.target.value,
      };
      return newBoxes;
    });
  };

  const updatePosition = (x, y, i, type) => {
    // e.preventDefault();
    // const index = e.target.id;
    const typee = type;
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide][typee][i] = {
        ...newBoxes[currentCardSide][typee][i],
        left: x,
      };
      newBoxes[currentCardSide][typee][i] = {
        ...newBoxes[currentCardSide][typee][i],
        top: y,
      };
      return newBoxes;
    });
  };

  const updateSize = (x, y, i, type) => {
    // e.preventDefault();
    // const index = e.target.id;
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide][type][i] = {
        ...newBoxes[currentCardSide][type][i],
        width: x,
      };
      newBoxes[currentCardSide][type][i] = {
        ...newBoxes[currentCardSide][type][i],
        height: y,
      };
      return newBoxes;
    });
  };
  const [fontValue, setFontValue] = useState(0);

  const changeFontFamily = (fontFamily) => {
    setFontFamily(fontFamily);
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide]["text_objects"][currentIndex] = {
        ...newBoxes[currentCardSide]["text_objects"][currentIndex],
        fontFamily: fontFamily,
      };
      return newBoxes;
    });
  };

  const changeFontSize = (v) => {
    setFontValue(v);
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide]["text_objects"][currentIndex] = {
        ...newBoxes[currentCardSide]["text_objects"][currentIndex],
        fontSize: v,
      };
      return newBoxes;
    });
  };

  const handleCardData = () => {
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes.id_front = {
        ...newBoxes.id_front,
        height: cardData.height,
        width: cardData.width,
      };
      newBoxes.id_back = {
        ...newBoxes.id_back,
        height: cardData.height,
        width: cardData.width,
      };
      return newBoxes;
    });
    setOpenDialog(false);
  };

  const showObject = () => {
    console.log(JSON.stringify(testObj));
    // console.log(testObj);
  };

  const handleColorChange = (color) => {
    setColor(color);
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide]["text_objects"][currentIndex] = {
        ...newBoxes[currentCardSide]["text_objects"][currentIndex],
        fill: color,
      };
      return newBoxes;
    });
  };

  const handleAlignment = (alignment) => {
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide]["text_objects"][currentIndex] = {
        ...newBoxes[currentCardSide]["text_objects"][currentIndex],
        textAlign: alignment,
      };
      return newBoxes;
    });
  };

  const handleFontWeight = (check) => {
    if (check) {
      setTestObj((prevState) => {
        const newBoxes = {
          ...prevState,
        };
        newBoxes[currentCardSide]["text_objects"][currentIndex] = {
          ...newBoxes[currentCardSide]["text_objects"][currentIndex],
          fontStyle: "bold",
        };
        return newBoxes;
      });
    } else {
      setTestObj((prevState) => {
        const newBoxes = {
          ...prevState,
        };
        newBoxes[currentCardSide]["text_objects"][currentIndex] = {
          ...newBoxes[currentCardSide]["text_objects"][currentIndex],
          fontStyle: "normal",
        };
        return newBoxes;
      });
    }
  };

  const handleImageBorder = (border) => {
    setRadius(border);
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide]["image_objects"][currentImgIndex] = {
        ...newBoxes[currentCardSide]["image_objects"][currentImgIndex],
        borderRadius: border,
      };
      return newBoxes;
    });
  };

  const handleImageStroke = (stroke) => {
    setBorder(stroke);
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide]["image_objects"][currentImgIndex] = {
        ...newBoxes[currentCardSide]["image_objects"][currentImgIndex],
        strokeWidth: stroke,
      };
      return newBoxes;
    });
  };

  const handleColorChangeBorder = (color) => {
    setColorBorder(color);
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide]["image_objects"][currentImgIndex] = {
        ...newBoxes[currentCardSide]["image_objects"][currentImgIndex],
        stroke: color,
      };
      return newBoxes;
    });
  };

  var node = document.getElementById("canvas");
  let scale = 2;
  const takepic = () => {
    domtoimage
      .toPng(node, { cacheBust: true })
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        const b64data = dataUrl.split(",")[1];
        // console.log("img");
        var a = document.createElement("a");
        a.href = "data:image/png;base64," + b64data;
        a.download = "Image.png";
        a.click();
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const onResize = (event, { element, size }) => {
    setSize({ width: size.width, height: size.height });
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setShowAddText(true);
  const handleClose = () => setOpen(false);

  const selectImage = (file) => {
    setSrc(URL.createObjectURL(file));
  };

  const [cardData, setCardData] = useState({
    type: 1,
    width: 0,
    height: 0,
    classid: 0,
    schoolid: 0,
    name: "",
  });

  const [tempText, setTepText] = useState({
    text: "",
    tag: "",
    max_length: 100,
    toolkit_text: "",
    prefix: "",
    options: "",
    text_format: "",
  });

  const [tempImg, setTempImg] = useState({
    tag: "",
    toolkit_text: "",
    removeBG: false,
    adminImage: false,
    src:""
  });

  const [testObj, setTestObj] = useState({
    id_card: {
      type: 0,
    },
    id_front: {
      img_bg: "",
      width: "",
      height: "",
      text_objects: [],
      image_objects: [],
    },
    id_back: {
      img_bg: "",
      width: "",
      height: "",
      text_objects: [],
      image_objects: [],
    },
  });

  var textObj = {
    type: "text",
    left: 10,
    top: 10,
    width: 60,
    height: 15,
    fill: "rgb(31, 26, 23)",
    fontFamily: "Saira Extra Condensed",
    fontSize: 18,
    text: "",
    prefix: "",
    tag: "",
    underline: false,
    textAlign: "left",
    fontStyle: "normal",
    toolkit_text: "",
    max_length: 100,
    options: "",
  };

  var imageObj = {
    type: "image",
    left: 10,
    top: 10,
    width: 50,
    height: 50,
    stroke: "rgb(40, 22, 111)",
    strokeWidth: 2,
    tag: "Photo",
    borderRadius: 0,
    toolkit_text: "",
    fontStyle: "normal",
    src: "",
    adminImage: false,
    removeBG: false,
  };

  const addtest = (text, tag, max_length, toolkit_text,prefix,options, text_format) => {
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide]["text_objects"] = [
        ...newBoxes[currentCardSide]["text_objects"],
        textObj,
      ];
      newBoxes[currentCardSide]["text_objects"][
        newBoxes[currentCardSide]["text_objects"].length - 1
      ] = {
        ...newBoxes[currentCardSide]["text_objects"][
          newBoxes[currentCardSide]["text_objects"].length - 1
        ],
        text: text,
        tag: tag,
        toolkit_text: toolkit_text,
        max_length: max_length,
        prefix:prefix,
        options:options,
        text_format: text_format,
      };
      return newBoxes;
    });
    // console.log(testObj);
    setShowAddText(false);
    setIsList(false);
    setTepText(
      Object.fromEntries(Object.keys(tempText).map((key) => [key, ""]))
    );

  };

  const addImage = (tag, toolkit_text,removeBG, adminImage, src ) => {
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide]["image_objects"] = [
        ...newBoxes[currentCardSide]["image_objects"],
        imageObj,
      ];
      newBoxes[currentCardSide]["image_objects"][
        newBoxes[currentCardSide]["image_objects"].length - 1
      ] = {
        ...newBoxes[currentCardSide]["image_objects"][
          newBoxes[currentCardSide]["image_objects"].length - 1
        ],
        tag: tag,
        toolkit_text: toolkit_text,
        removeBG: removeBG,
        adminImage: adminImage,
        src:src
      };
      return newBoxes;
    });
    // console.log(testObj);
    setShowAddImage(false);
    setTempImg({
      tag: "",
      toolkit_text: "",
      removeBG: false,
      adminImage: false,
      src: ""
    });
  };

   const updateTextProperties = (text, tag, max_length, toolkit_text, prefix, options) => {
     setTestObj((prevState) => {
       const newBoxes = {
         ...prevState,
       };
      newBoxes[currentCardSide]["text_objects"][currentIndex] = {
        ...newBoxes[currentCardSide]["text_objects"][currentIndex],
        text: text,
        tag: tag,
        toolkit_text: toolkit_text,
        max_length: max_length,
        prefix: prefix,
        options: options,
      };
       return newBoxes;
     });
     // console.log(testObj);
     setShowUpdateText(false);
     setIsList(false);
   };

  const updateImageProperties = (tag, toolkit_text, removeBG, adminImage, src) => {
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide]["image_objects"][currentImgIndex] = {
        ...newBoxes[currentCardSide]["image_objects"][
          currentImgIndex
        ],
        tag: tag,
        toolkit_text: toolkit_text,
        removeBG: removeBG,
        adminImage: adminImage,
        src: src,
      };
      return newBoxes;
    });
    setShowUpdateImage(false);
    }


  const onImageChange = (event, key) => {
    // event.preventDefault();
    // let file = event.target.files[0];
    setSrc({ ...src, [key]: URL.createObjectURL(event.target.files[0]) });
    setImages({ ...images, [key]: event.target.files[0] });
  };

   console.log(tempImg);

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setOutput(base64Image);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const style1 = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    textAlign: "center",
    position: "absolute",
    padding: 0,
  };

  const uploadIdCard = async () => {
    const fData = new FormData();

    fData.append("front", images.id_front);
    fData.append("back", images.id_back);
    fData.append("card_prop", JSON.stringify(testObj));
    fData.append("type", cardData.type);
    fData.append("name", cardData.name);
    fData.append("schoolid", cardData.schoolid);
    fData.append("classid", cardData.classid);

    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/createidcard`, fData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          enqueueSnackbar("ID card created successfully", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      });
  };

    const CreateLink = async (image) => {
      const fData = new FormData();
      fData.append("image", image);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/createlink`,
          fData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.data.success) {
          setTempImg({ ...tempImg, src: res.data.image_link });
        } else {
          throw new Error("Failed");
        }
      } catch (err) {
        console.log(err);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
        throw err;
      }
    };



  const updateIdCard = async () => {
    const fData = new FormData();

    fData.append("id", state.id);
    fData.append("front", images.id_front);
    fData.append("back", images.id_back);
    fData.append("card_prop", JSON.stringify(testObj));
    fData.append("type", cardData.type);
    fData.append("name", cardData.name);
    fData.append("schoolid", cardData.schoolid);
    fData.append("classid", cardData.classid);

    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/updateidcard`, fData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          enqueueSnackbar("ID card updated successfully", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Please fill all deatils !!!", {
          variant: "error",
        });
      });
  };

  const handleDeleteText = () => {
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide]["text_objects"].splice(currentIndex, 1);
      return newBoxes;
    });
  };

  const handleDeleteImage = () => {
    setTestObj((prevState) => {
      const newBoxes = {
        ...prevState,
      };
      newBoxes[currentCardSide]["image_objects"].splice(currentImgIndex, 1);
      return newBoxes;
    });
  };

  var fontNames = [
    {
      fontName: "Roboto Condensed",
      fontFamily: "Roboto Condensed, sans-serif",
      weights: [400, 700],
    },
    {
      fontName: "Oswald",
      fontFamily: "Oswald, sans-serif",
      weights: [400, 700],
    },
    {
      fontName: "Teko",
      fontFamily: "Teko, sans-serif",
      weights: [400, 700],
    },
    {
      fontName: "Anton",
      fontFamily: "Anton, sans-serif",
      weights: [400, 700],
    },
    {
      fontName: "Noto Sans Devanagari",
      fontFamily: "Noto Sans Devanagari, sans-serif",
      weights: [400, 700],
    },
    {
      fontName: "Antonio",
      fontFamily: "Antonio, sans-serif",
      weights: [400, 700],
    },
    {
      fontName: "BenchNine",
      fontFamily: "BenchNine, sans-serif",
      weights: [400, 700],
    },
    {
      fontName: "Markaxi Text",
      fontFamily: "Markaxi Text, sans-serif",
      weights: [400, 700],
    },
    {
      fontName: "Saira Extra Condensed",
      fontFamily: "Saira Extra Condensed, sans-serif",
      weights: [400, 700],
    },
    {
      fontName: "Tiro Devanagari Hindi",
      fontFamily: "Tiro Devanagari Hindi, sans-serif",
      weights: [400, 700],
    },
  ];

  const newFontArray = fontNames.map((font) => ({
    font: font.fontName,
    weights: font.weights,
  }));

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <center>
            <input type="file" onChange={onImageChange} className="filetype" />
            <br />
            <br />
            <div>
              {src && (
                <div>
                  <ReactCrop
                    src={src}
                    // onImageLoaded={setImage}
                    crop={crop}
                    onChange={setCrop}
                  />
                  <br />
                  <button onClick={cropImageNow}>Crop</button>
                  <br />
                  <br />
                </div>
              )}
            </div>

            <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
              <img src={images} width="20%" />
            </ReactCrop>
            <div>{output && <img src={output} />}</div>
          </center>
        </Box>
      </Modal>

      <StandardMuiDialogWrapper
        open={openDialog}
        setOpen={setOpenDialog}
        onExited={() => {}}
        dialogTitle="Add ID card"
        saveBtnLabel="Save"
        onSaveBtnClick={() => handleCardData()}
      >
        <StandardOutlinedTextfield
          label="Name"
          name="name"
          val={cardData.name}
          setVal={setCardData}
          spreadData={cardData}
        />
        <FormControl fullWidth style={{ marginTop: "15px" }}>
          <InputLabel id="demo-simple-select-label">School</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={cardData.schoolid}
            label="School"
            onChange={(e) =>
              setCardData({ ...cardData, schoolid: e.target.value })
            }
          >
            {allSchoolData?.map((c, i) => (
              <MenuItem value={c?.schoolid} key={i}>
                {c?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginTop: "15px" }}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={cardData.type}
            label="ID card Type"
            onChange={(e) => setCardData({ ...cardData, type: e.target.value })}
          >
            <MenuItem value={1}>One Sided</MenuItem>
            <MenuItem value={2}>Both Sided</MenuItem>
          </Select>
        </FormControl>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormControl fullWidth style={{ marginTop: "15px" }}>
              <InputLabel id="demo-simple-select-label">Size</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={[cardData.width, cardData.height]}
                label="ID card Type"
                onChange={(e) =>
                  setCardData({
                    ...cardData,
                    width: e.target.value[0],
                    height: e.target.value[1],
                  })
                }
              >
                <MenuItem value={[298, 465]}>Portrait</MenuItem>
                <MenuItem value={[465, 298]}>Landscape</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <StandardOutlinedTextfield
              type="number"
              label="Height"
              name="height"
              val={cardData.height}
              setVal={setCardData}
              spreadData={cardData}
            />
          </Grid>
          <Grid item xs={4}>
            <StandardOutlinedTextfield
              label="Width"
              type="number"
              val={cardData.width}
              name="width"
              setVal={setCardData}
              spreadData={cardData}
            />
          </Grid>
        </Grid>
        <br></br>
        Front Image:
        <Input
          type="file"
          onChange={(e) => onImageChange(e, "id_front")}
          disableUnderline
        ></Input>
        <br></br>
        {cardData.type == 2 ? (
          <div>
            {" "}
            Back Image:
            <Input
              type="file"
              onChange={(e) => onImageChange(e, "id_back")}
              disableUnderline
            />
          </div>
        ) : (
          <></>
        )}
      </StandardMuiDialogWrapper>

      <StandardMuiDialogWrapper
        open={showAddText}
        setOpen={setShowAddText}
        onExited={() => {}}
        dialogTitle="Add Text"
        saveBtnLabel="Add"
        onSaveBtnClick={() =>
          addtest(
            tempText.text,
            tempText.tag,
            tempText.max_length,
            tempText.toolkit_text,
            tempText.prefix,
            tempText.options,
            tempText.text_format
          )
        }
      >
        <StandardOutlinedTextfield
          label="Text"
          name="text"
          val={tempText.text}
          setVal={setTepText}
          spreadData={tempText}
        />
        <StandardOutlinedTextfield
          label="Tag"
          name="tag"
          val={tempText.tag}
          setVal={setTepText}
          spreadData={tempText}
        />
        <StandardOutlinedTextfield
          label="Prefix"
          name="prefix"
          val={tempText.prefix}
          setVal={setTepText}
          spreadData={tempText}
        />
        <StandardOutlinedTextfield
          label="Maximum Allowed Letters"
          name="max_length"
          val={tempText.max_length}
          setVal={setTepText}
          spreadData={tempText}
        />
        <StandardOutlinedTextfield
          label="Alert Message"
          name="toolkit_text"
          val={tempText.toolkit_text}
          setVal={setTepText}
          spreadData={tempText}
        />

        <FormControlLabel
          label="Dropdown List as Input"
          control={
            <Checkbox
              checked={isList}
              onChange={(e) => {
                setIsList(!isList);
              }}
            />
          }
        />
        <br />

        {isList && (
          <StandardOutlinedTextfield
            label="Enter Comma ',' Separated List (Eg. A,A+,B)"
            name="options"
            val={tempText.options}
            setVal={setTepText}
            spreadData={tempText}
          />
        )}

        <FormControl fullWidth style={{ marginTop: "15px" }}>
          <InputLabel id="demo-simple-select-label">Text Formating</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tempText.text_format}
            label="Text Formating"
            onChange={(e) =>
              setTepText({ ...tempText, text_format: e.target.value })
            }
          >
            <MenuItem value={"regular"}>Regular</MenuItem>
            <MenuItem value={"capitalize"}>Capitalize (ALL LETTERS)</MenuItem>
            <MenuItem value={"camelcase"}>
              Camel Case (Only First Letter)
            </MenuItem>
          </Select>
        </FormControl>
      </StandardMuiDialogWrapper>

      <StandardMuiDialogWrapper
        open={showUpdateText}
        setOpen={setShowUpdateText}
        onExited={() => {}}
        dialogTitle="Update Text"
        saveBtnLabel="Update"
        onSaveBtnClick={() =>
          updateTextProperties(
            currentTextObj?.text,
            currentTextObj?.tag,
            currentTextObj?.max_length,
            currentTextObj?.toolkit_text,
            currentTextObj?.prefix,
            currentTextObj?.options,
            currentTextObj?.text_format
          )
        }
      >
        <StandardOutlinedTextfield
          label="Text"
          name="text"
          val={currentTextObj?.text}
          setVal={setCurrentTextObj}
          spreadData={currentTextObj}
        />
        <StandardOutlinedTextfield
          label="Tag"
          name="tag"
          val={currentTextObj?.tag}
          setVal={setCurrentTextObj}
          spreadData={currentTextObj}
        />
        <StandardOutlinedTextfield
          label="Prefix"
          name="prefix"
          val={currentTextObj?.prefix}
          setVal={setCurrentTextObj}
          spreadData={currentTextObj}
        />
        <StandardOutlinedTextfield
          label="Maximum Allowed Letters"
          name="max_length"
          val={currentTextObj?.max_length}
          setVal={setCurrentTextObj}
          spreadData={currentTextObj}
        />
        <StandardOutlinedTextfield
          label="Alert Message"
          name="toolkit_text"
          val={currentTextObj?.toolkit_text}
          setVal={setCurrentTextObj}
          spreadData={currentTextObj}
        />

        <StandardOutlinedTextfield
          label="Enter Comma ',' Separated List (Eg. A,A+,B)"
          name="options"
          val={currentTextObj?.options}
          setVal={setCurrentTextObj}
          spreadData={currentTextObj}
        />

        <FormControl fullWidth style={{ marginTop: "15px" }}>
          <InputLabel id="demo-simple-select-label">Text Formating</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentTextObj.text_format}
            label="Text Formating"
            onChange={(e) =>
              setCurrentTextObj({
                ...currentTextObj,
                text_format: e.target.value,
              })
            }
          >
            <MenuItem value={"regular"}>Regular</MenuItem>
            <MenuItem value={"capitalize"}>Capitalize (ALL LETTERS)</MenuItem>
            <MenuItem value={"camelcase"}>
              Camel Case (Only First Letter)
            </MenuItem>
          </Select>
        </FormControl>
      </StandardMuiDialogWrapper>

      <StandardMuiDialogWrapper
        open={showAddImage}
        setOpen={setShowAddImage}
        onExited={() => {}}
        dialogTitle="Add Image"
        saveBtnLabel="Add"
        onSaveBtnClick={() =>
          addImage(
            tempImg?.tag,
            tempImg?.toolkit_text,
            tempImg?.removeBG,
            tempImg?.adminImage,
            tempImg?.src
          )
        }
      >
        <StandardOutlinedTextfield
          label="Tag"
          name="tag"
          val={tempImg.tag}
          setVal={setTempImg}
          spreadData={tempImg}
        />
        <StandardOutlinedTextfield
          label="Alert Message"
          name="toolkit_text"
          val={tempImg.toolkit_text}
          setVal={setTempImg}
          spreadData={tempImg}
        />
        <FormControlLabel
          label="Remove Background"
          control={
            <Checkbox
              checked={tempImg.removeBG}
              onChange={(e) => {
                setTempImg({ ...tempImg, removeBG: e.target.checked });
              }}
            />
          }
        />
        <br />
        <FormControlLabel
          label="Admin Image (Uploading Not allowed for user)"
          control={
            <Checkbox
              checked={tempImg.adminImage}
              onChange={(e) => {
                setTempImg({ ...tempImg, adminImage: e.target.checked });
              }}
            />
          }
        />
        {tempImg.adminImage && (
          <Input
            type="file"
            onChange={(e) => {
              CreateLink(e.target.files[0]);
            }}
            disableUnderline
          />
        )}
      </StandardMuiDialogWrapper>

      <StandardMuiDialogWrapper
        open={showUpdateImage}
        setOpen={setShowUpdateImage}
        onExited={() => {}}
        dialogTitle="Update Image"
        saveBtnLabel="Update"
        onSaveBtnClick={() =>
          updateImageProperties(
            currentImgObj?.tag,
            currentImgObj?.toolkit_text,
            currentImgObj?.removeBG,
            currentImgObj?.adminImage,
            currentImgObj?.src
          )
        }
      >
        <StandardOutlinedTextfield
          label="Tag"
          name="tag"
          val={currentImgObj?.tag}
          setVal={setCurrentImgObj}
          spreadData={currentImgObj}
        />
        <StandardOutlinedTextfield
          label="Alert Message"
          name="toolkit_text"
          val={currentImgObj?.toolkit_text}
          setVal={setCurrentImgObj}
          spreadData={currentImgObj}
        />
        <FormControlLabel
          label="Remove Background"
          control={
            <Checkbox
              checked={currentImgObj?.removeBG}
              onChange={(e) => {
                setCurrentImgObj({
                  ...currentImgObj,
                  removeBG: e.target.checked,
                });
              }}
            />
          }
        />
      </StandardMuiDialogWrapper>

      <GoogleFontLoader
        fonts={newFontArray}
        subsets={["cyrillic-ext", "greek"]}
      />

      <div>
        <Grid container>
          <Grid xs={9}>
            <div className="container">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(e) => setPreviewMode(e.target.checked)}
                      />
                    }
                    label="Preview"
                  />
                </FormGroup>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentCardSide}
                  onChange={(e) => setCurrentCardSide(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={"id_front"}>Front Side</MenuItem>
                  {cardData.type == 2 && (
                    <MenuItem value={"id_back"}>Back Side</MenuItem>
                  )}
                </Select>
              </div>

              <div className="row2">
                {" "}
                <div>
                  <div
                    style={{ height: "90vh", overflowY: "auto", width: "100%" }}
                  >
                    <div
                      id="top"
                      style={{ display: "inline-flex", position: "relative" }}
                    >
                      <div
                        id="canvas"
                        style={{
                          width: cardData.width + "px",
                          height: cardData.height + "px",
                          position: "absolute",
                          backgroundImage: `url(${
                            state || src[currentCardSide]
                              ? src[currentCardSide]
                                ? src[currentCardSide]
                                : currentCardSide === "id_front"
                                ? state.document_path_front
                                : state.document_path_back
                              : ""
                          })`,
                        }}
                      >
                        {testObj[currentCardSide]["image_objects"].map(
                          (item, i) => {
                            return (
                              <Rnd
                                style={{
                                  ...style1,
                                  border: previewMode
                                    ? "0px"
                                    : currentImgIndex === i
                                    ? "dashed 1px rgb(255, 0, 0)"
                                    : "dashed 1px #000000",
                                }}
                                size={{
                                  width: item.width,
                                  height: item.height,
                                }}
                                position={{ x: item.left, y: item.top }}
                                onDragStop={(e, d) => {
                                  updatePosition(d.x, d.y, i, "image_objects");
                                }}
                                onResizeStop={(
                                  e,
                                  direction,
                                  ref,
                                  delta,
                                  position
                                ) => {
                                  updateSize(
                                    ref.style.width,
                                    ref.style.height,
                                    i,
                                    "image_objects"
                                  );
                                }}
                                bounds="parent"
                              >
                                <div
                                  onDoubleClick={() => {
                                    setCurrentImgIndex(i);
                                    setBorder(item.strokeWidth);
                                    setColorBorder(item.stroke);
                                    setRadius(item.borderRadius);
                                  }}
                                  style={{
                                    width: item.width,
                                    height: item.height,
                                    borderRadius: item.borderRadius,
                                    border:
                                      item.strokeWidth +
                                      "px solid " +
                                      item.stroke,
                                    position: "absolute",
                                    backgroundColor: "rgb(207, 207, 207)",
                                    ...(item.src
                                      ? {
                                          backgroundColor: "#ffffff00",
                                          backgroundSize: "100% 100%",
                                          backgroundRepeat: "no-repeat",
                                          backgroundImage: `url(${
                                            item.src ? item.src : ""
                                          })`,
                                        }
                                      : {}),
                                  }}
                                ></div>
                              </Rnd>
                            );
                          }
                        )}
                        {testObj[currentCardSide]["text_objects"].map(
                          (item, i) => {
                            return (
                              <Rnd
                                style={{
                                  ...style1,
                                  border: previewMode
                                    ? "0px"
                                    : currentIndex === i
                                    ? "dashed 1px rgb(255, 0, 0)"
                                    : "dashed 1px #000000",
                                }}
                                // style={{border:previewMode? "dashed 1px #000000":"0px"}}
                                size={{
                                  width: item.width,
                                  height: item.height,
                                }}
                                position={{ x: item.left, y: item.top }}
                                onDragStop={(e, d) => {
                                  updatePosition(d.x, d.y, i, "text_objects");
                                }}
                                onResizeStop={(
                                  e,
                                  direction,
                                  ref,
                                  delta,
                                  position
                                ) => {
                                  updateSize(
                                    ref.style.width,
                                    ref.style.height,
                                    i,
                                    "text_objects"
                                  );
                                }}
                                bounds="parent"
                              >
                                <Input
                                  onFocus={() => {
                                    setCurrentIndex(i);
                                    setFontValue(item.fontSize);
                                    setFontFamily(item.fontFamily);
                                    setColor(item.fill);
                                    setChecked(
                                      item.fontStyle === "bold" && true
                                    );
                                  }}
                                  sx={{ input: { textAlign: item.textAlign } }}
                                  onChange={handleTextChange}
                                  value={item.text}
                                  placeholder="TEXT"
                                  id={i}
                                  type={item.type}
                                  style={{
                                    textAlign: "center",
                                    width: "100%",
                                    fontWeight: item.fontStyle,
                                    fontSize: item.fontSize,
                                    color: item.fill,
                                    fontFamily: item.fontFamily,
                                    margin: 0,
                                    padding: "0px",
                                    position: "absolute",
                                    overflowWrap: "break-word",
                                  }}
                                  disableUnderline
                                />
                              </Rnd>
                            );
                          }
                        )}
                      </div>
                      <div style={{ width: "25px" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={3}>
            <div>
              <div
                style={{ width: "100%", height: "100vh", overflowY: "scroll" }}
              >
                <Card className="cardawithshadow">
                  <div id="box">
                    <Button
                      className="card"
                      variant="outlined"
                      onClick={onAddClass}
                      style={{ width: "auto" }}
                      startIcon={<AccountBoxRounded />}
                    >
                      Create ID Card
                    </Button>
                    <div id="box1">
                      <Button
                        className="card"
                        variant="outlined"
                        onClick={handleOpen}
                      >
                        <div>
                          <TextIncreaseRounded />
                        </div>
                        <div>Text</div>
                      </Button>
                      <Button
                        className="card"
                        variant="outlined"
                        onClick={() => setShowAddImage(true)}
                      >
                        <div>
                          <AddPhotoAlternateIcon />
                        </div>
                        <div>Image</div>
                      </Button>
                    </div>
                    <div id="details">
                      <p style={{ textAlign: "center" }}>
                        <b>TEXT OPTIONS</b>
                      </p>
                      <hr />
                      <Button onClick={() => handleFontWeight(!checked)}>
                        Bold
                      </Button>
                      <br></br>
                      <br></br>
                      <row>
                        <MuiColorInput
                          value={color}
                          onChange={handleColorChange}
                        />
                      </row>
                      <br></br>
                      <br></br>
                      <Grid container>
                        <Grid item xs={6}>
                          <b>Font Size</b>
                        </Grid>
                        <Grid item xs={4}>
                          <input
                            type="number"
                            value={fontValue}
                            onChange={(e) => {
                              changeFontSize(parseInt(e.target.value));
                            }}
                            style={{ width: "60px", textAlign: "center" }}
                            Length={2}
                          />
                        </Grid>
                      </Grid>
                      <Slider
                        defaultValue={12}
                        aria-label="Disabled slider"
                        value={fontValue}
                        min={10}
                        max={40}
                        onChange={(e, v) => changeFontSize(v)}
                      />
                      <br></br>
                      <br></br>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Font Family
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={fontFamily}
                          label="Font Family"
                          onChange={(e) => changeFontFamily(e.target.value)}
                        >
                          {fontNames?.map((c, i) => (
                            <MenuItem value={c?.fontFamily} key={i}>
                              {c?.fontName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <br></br> <br></br>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button onClick={() => handleAlignment("left")}>
                          Left{" "}
                        </Button>
                        <Button onClick={() => handleAlignment("center")}>
                          Center{" "}
                        </Button>
                        <Button onClick={() => handleAlignment("right")}>
                          Right{" "}
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 50,
                          paddingTop: 10,
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setCurrentTextObj(
                              testObj[currentCardSide]["text_objects"][
                                currentIndex
                              ]
                            );
                            setShowUpdateText(true);
                          }}
                          startIcon={<DisplaySettingsIcon />}
                          style={{ width: "48%" }}
                        >
                          UPDATE
                        </Button>
                        <Button
                          color="error"
                          variant="outlined"
                          onClick={() => handleDeleteText()}
                          startIcon={<DeleteIcon />}
                          style={{ width: "48%" }}
                        >
                          DELETE
                        </Button>
                      </div>
                    </div>
                    <br></br>
                    <div id="details">
                      <p style={{ textAlign: "center" }}>
                        <b>IMAGE OPTIONS</b>
                      </p>
                      <hr />
                      <Grid container>
                        <Grid item xs={6}>
                          <b>Image Radius</b>
                        </Grid>
                        <Grid item xs={4}>
                          <input
                            type="number"
                            value={radius}
                            onChange={(e) => {
                              handleImageBorder(parseInt(e.target.value));
                            }}
                            style={{ width: "60px", textAlign: "center" }}
                            Length={2}
                          />
                        </Grid>
                      </Grid>
                      <Slider
                        defaultValue={0}
                        aria-label="Disabled slider"
                        value={radius}
                        min={0}
                        max={100}
                        onChange={(e, v) => handleImageBorder(v)}
                      />
                      <br></br>
                      <Grid container>
                        <Grid item xs={6}>
                          <b>Image Border</b>
                        </Grid>
                        <Grid item xs={4}>
                          <input
                            type="number"
                            value={border}
                            onChange={(e) => {
                              handleImageStroke(parseInt(e.target.value));
                            }}
                            style={{ width: "60px", textAlign: "center" }}
                            Length={2}
                          />
                        </Grid>
                      </Grid>
                      {/* <b>Image Border</b> <span>{border}</span> */}
                      <Slider
                        defaultValue={0}
                        aria-label="Disabled slider"
                        value={border}
                        min={0}
                        max={100}
                        onChange={(e, v) => handleImageStroke(v)}
                      />
                      <br></br>
                      <row>
                        <MuiColorInput
                          value={colorBorder}
                          onChange={handleColorChangeBorder}
                        />
                      </row>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 50,
                          paddingTop: 10,
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setCurrentImgObj(
                              testObj[currentCardSide]["image_objects"][
                                currentImgIndex
                              ]
                            );
                            setShowUpdateImage(true);
                          }}
                          startIcon={<DisplaySettingsIcon />}
                          style={{ width: "48%" }}
                        >
                          UPDATE
                        </Button>
                        <Button
                          color="error"
                          variant="outlined"
                          onClick={() => handleDeleteImage()}
                          startIcon={<DeleteIcon />}
                          style={{ width: "48%" }}
                        >
                          DELETE
                        </Button>
                      </div>
                    </div>
                    {/* Input Excel File <span>{}</span>
                    <Input type="file" disableUnderline /> */}
                    <br></br>
                    <Button onClick={() => takepic()}>Download ID Card</Button>
                    {/* <Button onClick={() => takepic()}>Download All</Button> */}
                    {/* <Button onClick={() => addtest()}>Add Object</Button> */}
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (state) {
                          updateIdCard();
                        } else {
                          uploadIdCard();
                        }
                      }}
                      style={{ width: "auto" }}
                    >
                      {state ? (
                        <div>Update ID Card</div>
                      ) : (
                        <div>Upload ID Card</div>
                      )}
                      {/* <h5>{testObj.id_front.text_objects[0].text}</h5> */}
                    </Button>

                    {/* <Button onClick={() => showObject()}>Show Object</Button> */}
                    <hr style={{ backgroundColor: "black" }}></hr>
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
