import './StandardButton.css';
import { Button, CircularProgress } from '@mui/material';

function StandardButton({ label, startIcon, onClick, color, variant, loading, style }) {
    return (
        <Button variant={variant || 'text'} onClick={onClick} sx={{ letterSpacing: 1, color: color, ...style }}
            disabled={loading} startIcon={startIcon || ''}
        >
            {loading ? <CircularProgress style={{ color: color }} size={18} /> : label}
        </Button>
    )
}

export default StandardButton