import { useEffect, useState } from "react";
import { Button, Input, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// Components
import StandardPageWrapper from "../../components/StandardPageWrapper/StandardPageWrapper";
import ReactCustomTable from "../../components/ReactCustomTable/ReactCustomTable";
// ICONs & SVGs
import { LibraryBooks, Edit, Delete, FileCopy } from "@mui/icons-material";
import Prompt from "mui-prompt";
import FormControl from "@mui/material/FormControl";
import StandardMuiDialogWrapper from "../../components/StandardMuiDialogWrapper/StandardMuiDialogWrapper";
import StandardOutlinedTextfield from "../../components/StandardOutlinedTextfield/StandardOutlinedTextfield";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function IdCards() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [allIdCardsData, setAllIdCardsData] = useState([]);
  const [allIdCardsDataLoading, setAllIdCardsDataLoading] = useState(true);
  // Delete
  const [deleteID, setDeleteID] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [cardData, setCardData] = useState({
    id: 0,
    schoolid: 0,
    name: "",
  });
  const [allSchoolData, setAllSchoolData] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    fetchAllIdCards();
  }, []);

  const fetchAllSchools = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/user/getallschool`)
      .then((res) => {
        if (res.data.success) {
          setAllSchoolData(res.data.data);
        } else {
          enqueueSnackbar("Error Fetching Schools, Try Again !!!", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const fetchAllIdCards = async () => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/getallidcard`)
      .then((res) => {
        if (res.data.success) {
          setAllIdCardsData(res.data.data);
        } else {
          enqueueSnackbar("Error Fetching Schools, Try Again !!!", {
            variant: "error",
          });
        }
        setAllIdCardsDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAllIdCardsDataLoading(false);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const onDelete = async (e, data) => {
    Prompt.ask("onDelete", {
      title: "CAUTION !!!",
      body: "Deleting ID card will delete all the entries associated with that IDcard",
      cancel: "Cancel",
      continue: "DELETE",
      callback: async (status) => {
        if (status) {
          e.stopPropagation();
          if (!data.id)
            return enqueueSnackbar("IDcard Not Found !!!", {
              variant: "error",
            });
          setDeleteID(data.id);
          setDeleteLoading(true);
          await axios
            .post(`${process.env.REACT_APP_BASE_URL}/user/deleteidcard`, {
              icardid: data.id,
            })
            .then((res) => {
              if (res.data.success) {
                enqueueSnackbar("IDcard Deleted Successfully !!!", {
                  variant: "success",
                });
              } else {
                enqueueSnackbar("Error Deleting IDcard, Try Again !!!", {
                  variant: "error",
                });
              }
              setDeleteID("");
              setDeleteLoading(false);
              fetchAllIdCards();
              Prompt.cancel("onDelete");
            })
            .catch((err) => {
              console.log(err);
              setDeleteLoading(false);
              setDeleteID("");
              return enqueueSnackbar("Something Went Wrong !!!", {
                variant: "error",
              });
            });
        } else {
          Prompt.cancel("onDelete");
        }
      },
    });
  };

  const onDublicate = (e, data) => {
    setCardData({ ...cardData, id: data.id });
    fetchAllSchools();
    setOpenDialog(true);
  };

  const onNavigatetoUpdate = (e, data) =>
    navigate(`/idcards/${data.id}`, { state: data });

  const onNavigatetoCreate = () => navigate(`/idcards/create`);

  const onExcelClick = (e, data) =>
    navigate("/download-by-excel", { state: { idCard_id: data.id } });

  const dublicateIdCard = async (e, data) => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/createdublicatecard`, {
        idcard_id: cardData.id,
        schoolid: cardData.schoolid,
        name: cardData.name,
      })
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("ID card created successfully !!!", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Error creating IDcard, Try Again !!!", {
            variant: "error",
          });
        }
        setCardData({
          id: 0,
          schoolid: 0,
          name: "",
        });
        setOpenDialog(false);
        fetchAllIdCards();
      })
      .catch((err) => {
        console.log(err);
        setCardData({
          id: 0,
          schoolid: 0,
          name: "",
        });
        setOpenDialog(false);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const tableActions = [
    {
      icon: <Edit sx={{ cursor: "pointer", color: "#7978e9" }} />,
      onClick: onNavigatetoUpdate,
    },
    {
      icon: <LibraryBooks sx={{ cursor: "pointer" }} color="success" />,
      onClick: onExcelClick,
    },
    {
      icon: <Delete sx={{ cursor: "pointer" }} color="error" />,
      onClick: onDelete,
      btnType: "del",
    },
    {
      icon: <FileCopy sx={{ cursor: "pointer" }} color="warning" />,
      onClick: onDublicate,
      btnType: "del",
    },
  ];

  const filterIdCards = (data) => {
    return data.filter((card) => {
      const searchString =
        `${card.id} ${card.name} ${card.type} ${card.schoolid}`.toLowerCase();
      return searchString.includes(searchInput.toLowerCase());
    });
  };

  return (
    <StandardPageWrapper
      header="ID Cards"
      btns={
        <>
          <Button
            variant="contained"
            component="label"
            onClick={() => onNavigatetoCreate()}
          >
            Create IDCard
          </Button>
        </>
      }
    >
      <TextField
        sx={{ marginRight: "30px", marginLeft: "10px" }}
        variant="outlined"
        type="text"
        fullWidth
        placeholder="Search ID Cards..."
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <StandardMuiDialogWrapper
        open={openDialog}
        setOpen={setOpenDialog}
        onExited={() => {}}
        dialogTitle="Dublicate ID card"
        saveBtnLabel="Create"
        onSaveBtnClick={() => dublicateIdCard()}
      >
        <StandardOutlinedTextfield
          label="Name"
          name="name"
          val={cardData.name}
          setVal={setCardData}
          spreadData={cardData}
        />
        <FormControl fullWidth style={{ marginTop: "15px" }}>
          <InputLabel id="demo-simple-select-label">School</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={cardData.schoolid}
            label="School"
            onChange={(e) =>
              setCardData({ ...cardData, schoolid: e.target.value })
            }
          >
            {allSchoolData?.map((c, i) => (
              <MenuItem value={c?.schoolid} key={i}>
                {c?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StandardMuiDialogWrapper>
      <Prompt.View />
      <div id="schools">
        <ReactCustomTable
          data={filterIdCards(allIdCardsData)}
          tableColumns={tableColumns}
          loading={allIdCardsDataLoading}
          tableActions={tableActions}
          actionsColumnWidth={150}
          deleteID={deleteID}
          deleteLoading={deleteLoading}
          rowDataDeleteKey="schoolid"
          circularColor="#d32f2f"
        />
      </div>
    </StandardPageWrapper>
  );
}

export default IdCards;

const tableColumns = [
  {
    label: "Id Card ID",
    key: "id",
    width: 100,
    align: "center",
  },
  {
    label: "Name",
    key: "name",
    width: 500,
    fixed: false,
  },
  {
    label: "Type",
    key: "type",
    width: 50,
    fixed: false,
    align: "center",
  },
  {
    label: "School ID",
    key: "schoolid",
    width: 20,
    flexGrow: 1,
  },
];
