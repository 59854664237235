import { TextField } from '@mui/material';

function StandardOutlinedTextfield({ label, name, val, setVal, type, spreadData }) {

    const handleChange = (e) => {
        if(!e.target.name) {
            setVal(e.target.value);
        } else {
            setVal({ ...spreadData, [e.target.name]: e.target.value })
        }
    }

    return (
        <TextField label={label} variant="outlined" name={name} value={val} fullWidth sx={{ marginTop: 1.5 }}
            type={type || 'text'} onChange={handleChange}
        />
    )
}

export default StandardOutlinedTextfield