import "./Users.css";
import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";
// Components
import StandardPageWrapper from "../../components/StandardPageWrapper/StandardPageWrapper";
import StandardOutlinedTextfield from "../../components/StandardOutlinedTextfield/StandardOutlinedTextfield";
import ReactCustomTable from "../../components/ReactCustomTable/ReactCustomTable";
import CustomMuiSelect from "../../components/CustomMuiSelect/CustomMuiSelect";

function Users() {
  const { enqueueSnackbar } = useSnackbar();

  const [allUserData, setAllUserData] = useState([]);
  const [allUserDataLoading, setAllUserDataLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [addUserData, setAddUserData] = useState({
    first_name: "",
    phone_no: "",
    role: "",
    classid: "",
    schoolid: "",
  });
  const [allBtnData, setAllBtnData] = useState({ school: "", class: "" });
  const [allSchoolData, setAllSchoolData] = useState([]);
  const [allCLassData, setAllCLassData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");


  const [allSchoolDataLoading, setAllSchoolDataLoading] = useState(true);
  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/user/all-user`)
      .then((res) => {
        if (res.data.success) {
          setAllUserData(res.data.data);
        } else {
          enqueueSnackbar("Error Fetching Users, Try Again !!!", {
            variant: "error",
          });
        }
        setAllUserDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAllUserDataLoading(false);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const fetchAllSchools = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/user/getallschool`)
      .then((res) => {
        if (res.data.success) {
          setAllSchoolData(res.data.data);
        } else {
          enqueueSnackbar("Error Fetching Schools, Try Again !!!", {
            variant: "error",
          });
        }
        setAllSchoolDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAllSchoolDataLoading(false);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const fetchAllClass = async (e) => {
    setAllBtnData({ ...allBtnData, school: e.target.value, class: "" });

    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/getclassesbyschoolid`, {
        schoolid: e.target.value,
      })
      .then((res) => {
        if (res.data.success) {
          setAllCLassData(res.data.data);
        } else {
          enqueueSnackbar("Error Fetching Class, Try Again !!!", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

  const onAddUser = async () => {
    if (Object.values(addUserData)?.includes(""))
      return enqueueSnackbar("Please Fill All The Details !!!", {
        variant: "error",
      });

    const body = { ...addUserData, is_verified: 1 };
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/signup`, body)
      .then((res) => {
        if (res.data.success) {
          setAllUserData([...allUserData, res.data.data[0]]);
          enqueueSnackbar("User Added Successfully !!!", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
        setOpenDialog(false);
        setAddUserData({
          first_name: "",
          phone_no: "",
          role: "",
          classid: "",
          schoolid: "",
        });
      })
      .catch((err) => {
        console.log(err);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

    const filterIdCards = (data) => {
      return data.filter((card) => {
        const searchString =
          `${card.id} ${card.first_name} ${card.classid} ${card.schoolid} ${card.phone_no}`.toLowerCase();
        return searchString.includes(searchInput.toLowerCase());
      });
    };

  return (
    <StandardPageWrapper
      header="Users"
      btns={
        <>
          <Button
            variant="contained"
            component="label"
            onClick={() => {
              setOpenDialog(true);
              fetchAllSchools();
            }}
          >
            Add User
          </Button>
        </>
      }
    >
      <TextField
        sx={{ marginRight: "30px", marginLeft: "10px" }}
        variant="outlined"
        type="text"
        fullWidth
        placeholder="Search Users..."
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <div id="users">
        <ReactCustomTable
          data={filterIdCards(allUserData)}
          tableColumns={tableColumns}
          loading={allUserDataLoading}
          onRowClick={() => {}}
        />
      </div>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDialog}
        className="users_addDialog"
      >
        <DialogTitle className="users_addDialog_title">Add Users</DialogTitle>
        <DialogContent>
          <StandardOutlinedTextfield
            label="First Name"
            name="first_name"
            val={addUserData.first_name}
            setVal={setAddUserData}
            spreadData={addUserData}
          />
          <StandardOutlinedTextfield
            label="Phone Number"
            name="phone_no"
            type="number"
            val={addUserData.phone_no}
            setVal={setAddUserData}
            spreadData={addUserData}
          />
          {/* <StandardOutlinedTextfield
            label="Class ID"
            name="classid"
            val={addUserData.classid}
            setVal={setAddUserData}
            spreadData={addUserData}
            type="number"
          /> */}

          <TextField
            fullWidth
            select
            label="School"
            value={addUserData.schoolid}
            onChange={(e) => {
              setAddUserData({ ...addUserData, schoolid: e.target.value });
              fetchAllClass(e);
            }}
            sx={{ marginTop: 1.5 }}
          >
            {/* <MenuItem value='admin'>Admin</MenuItem>
                        <MenuItem value='principal'>Principal</MenuItem> */}
            {allSchoolData?.map((c, i) => (
              <MenuItem value={c?.schoolid} key={i}>
                {c?.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            select
            label="Class"
            value={addUserData.classid}
            onChange={(e) =>
              setAddUserData({ ...addUserData, classid: e.target.value })
            }
            sx={{ marginTop: 1.5 }}
          >
            {/* <MenuItem value='admin'>Admin</MenuItem>
                        <MenuItem value='principal'>Principal</MenuItem> */}
            {allCLassData?.map((c, i) => (
              <MenuItem value={c?.classid} key={i}>
                {c?.division}
              </MenuItem>
            ))}
          </TextField>

          {/* <CustomMuiSelect label='Select School' val={allBtnData.school} onChange={fetchAllClass} width={150} size='small' disabled={searchLoading} >
                    {allSchoolData?.map((c, i) => <MenuItem value={c?.schoolid} key={i} >{c?.name}</MenuItem>)}
                </CustomMuiSelect> */}

          {/* <CustomMuiSelect label='Select Class' val={allBtnData.class} onChange={(e) => setAllBtnData({ ...allBtnData, class: e.target.value })} width={150} size='small' sx={{ marginRight: 1.5 }} disabled={searchLoading} >
                    {allCLassData?.map((c, i) => <MenuItem value={c?.classid} key={i} >{c?.division}</MenuItem>)}
                </CustomMuiSelect> */}

          {/* <StandardOutlinedTextfield
            label="School ID"
            name="schoolid"
            val={addUserData.schoolid}
            setVal={setAddUserData}
            spreadData={addUserData}
            type="number"
          /> */}
          {/* <StandardOutlinedTextfield label="Last Name" name='last_name' val={addUserData.last_name} setVal={setAddUserData} spreadData={addUserData} /> */}
          {/* <StandardOutlinedTextfield label="Email" name='email' type='email' val={addUserData.email} setVal={setAddUserData} spreadData={addUserData} />
                    <StandardOutlinedTextfield label="Password" name='password' val={addUserData.password} setVal={setAddUserData} spreadData={addUserData} /> */}

          <TextField
            fullWidth
            select
            label="Role"
            value={addUserData.role}
            onChange={(e) =>
              setAddUserData({ ...addUserData, role: e.target.value })
            }
            sx={{ marginTop: 1.5 }}
          >
            {/* <MenuItem value='admin'>Admin</MenuItem>
                        <MenuItem value='principal'>Principal</MenuItem> */}
            <MenuItem value="student">Student</MenuItem>

            <MenuItem value="teacher">Teacher</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => setOpenDialog(false)}
            sx={{ fontWeight: 600, letterSpacing: 1 }}
          >
            Cancel
          </Button>
          <Button
            color="success"
            onClick={onAddUser}
            sx={{ fontWeight: 600, letterSpacing: 1 }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </StandardPageWrapper>
  );
}

export default Users;

const tableColumns = [
  {
    label: "ID",
    key: "id",
    width: 40,
    fixed: true,
  },
  {
    label: "Name",
    key: "first_name",
    minWidth: 170,
    flexGrow: 1,
    fixed: false,
    fullText: true,
  },
  // {
  //     label: 'Last Name',
  //     key: 'last_name',
  //     minWidth: 170,
  //     flexGrow: 1,
  //     fixed: false,
  //     fullText: true
  // },
  // {
  //     label: 'Email',
  //     key: 'email',
  //     width: 450,
  //     fixed: false,
  //     fullText: true
  // },
  {
    label: "Phone Number",
    key: "phone_no",
    width: 120,
    fixed: false,
  },
  {
    label: "Role",
    key: "role",
    width: 100,
    fixed: false,
  },
  {
    label: "School ID",
    key: "schoolid",
    width: 75,
    fixed: false,
  },
  {
    label: "Class ID",
    key: "classid",
    width: 65,
    fixed: false,
    align: "center",
  }
];
