import './Login.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Button, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import axios from 'axios';
import CryptoJS from 'crypto-js';
// ICONs & SVGs
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Logo from "../../img/final_logo.png";

function Login() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password) return enqueueSnackbar('Please Fill All The Details !!!', { variant: 'error' })

    if (username == 'GjaiiPublication' && password == 'Gjaii@5931') {
      navigate('/idcards');
      sessionStorage.setItem('ID_CardAdmin_user_auth', CryptoJS.AES.encrypt('false', process.env.REACT_APP_CRYPTO_JS_SECRET).toString());
      sessionStorage.setItem("ID_CardAdmin_user", "admin");
    } else {
      setLoading(true);
      const body = { school_login_id: username, secret_code: password }
      await axios.post(`${process.env.REACT_APP_BASE_URL}/user/schoollogin`, body)
        .then(async res => {
          if (res.data.success) {
            console.log(res.data);
            sessionStorage.setItem('ID_CardAdmin_user', JSON.stringify(res.data.result[0]))
            sessionStorage.setItem('ID_CardAdmin_user_auth', CryptoJS.AES.encrypt('true', process.env.REACT_APP_CRYPTO_JS_SECRET).toString())
            navigate(`/schools/${res.data.result[0].schoolid}`, { state: res.data.result[0] })
            setLoading(false);
            enqueueSnackbar('Login Successful !!!', { variant: 'success' });
          } else {
            enqueueSnackbar(res.data.result, { variant: 'error' });
            setLoading(false);
          }
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
          enqueueSnackbar('Error, Please Try Again !!!', { variant: 'error' });
        })
    }
  }

  return (
    <div id="login">
      <img className="sidemenu_logo" style={{ width: "200px" }} src={Logo} />
      <form className="login_form" onSubmit={handleSubmit}>
        <TextField
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          sx={{ marginBottom: 2 }}
          label="Username"
          variant="outlined"
        />

        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        <Button
          size="large"
          variant="contained"
          type="submit"
          fullWidth
          disabled={loading}
          sx={{ backgroundColor: "#4b49ac !important", marginTop: 3.5 }}
        >
          {!loading ? (
            "Login"
          ) : (
            <CircularProgress size={27} sx={{ color: "#fff" }} />
          )}
        </Button>
      </form>
    </div>
  );
}

export default Login